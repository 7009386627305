import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForceChangePasswordComponent } from "./force-change-password.component";
import { FormModule } from "../../shared/components/default/form/form.module";

@NgModule({
	declarations: [
		ForceChangePasswordComponent
	],
	imports: [
		CommonModule,
		FormModule,
	]
})
export class ForceChangePasswordModule {
}
