export const WELCOME_TRANSLATE = {
    welcome: {
        actions: {
            continue: {
                text: "Continuar"
            }
        },
        save: {
            form: {
                fields: {
                    acceptUseTerms: {
                        label: "Li e concordo com os termos acima"
                    }
                }
            }
        }
    }
}