import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from "@ngrx/store";
import { tap } from "rxjs";

import { SignInService } from "../../../modules/sign-in/sign-in.service";
import { MenuService } from "../menu.service";
import { MenuToolbarService } from "./menu-toolbar.service";
import { environment } from "../../../../environments/environment";
import { GlobalLoadingService } from "../../../shared/components/default/global-loading/global-loading.service";
import { routeSelectors, toolbarSelectors } from "../../../shared/store/selectors";
import { ToolbarAction } from "../../../shared/models/toolbar-action";
import { Authentication } from "../../authentication/authentication";
import { Translate } from "../../../shared/helpers/translate";
import { BaseSubscriber } from "../../../shared/base/base-subscriber";
import { subscribeOnce } from "../../../shared/rxjs-operators";

@Component({
    selector: 'app-menu-toolbar',
    templateUrl: './menu-toolbar.component.html',
    styleUrls: ['./menu-toolbar.component.scss'],
    preserveWhitespaces: false
})
export class MenuToolbarComponent extends BaseSubscriber {
    env = environment;
    isOpen = true;
    projectName = 'JoinIn';
    actions$ = this.store.select(toolbarSelectors.selectToolbarActions(this));

    user = Authentication.getUser();

    constructor(
        public signInService: SignInService,
        public menuService: MenuService,
        public router: Router,
        public toolbarServ: MenuToolbarService,
        public globalLoadingService: GlobalLoadingService,
        public store: Store,
    ) {
        super();
        this.toolbarServ.isOpenSubject.subscribe(state => this.isOpen = state);

        this.subscribe(Authentication.userChangedSuject.pipe(tap(resp => {
            this.user = resp;
        })))


    }

    onActionClick(action: ToolbarAction) {
        action?.click?.();
    }

    executeBadge(badge: any) {
        const value = badge?.value?.();
        return {...badge, value};
    }

    urlProfile() {
        const isAtAdminArea = subscribeOnce(this.store.select(routeSelectors.selectIsAtAdminArea));
        if(isAtAdminArea)
            return ''
        return Translate.value('routes.myProfile.account.path');
    }


    get avatarProfileTooltip(){
        const isAtAdminArea = subscribeOnce(this.store.select(routeSelectors.selectIsAtAdminArea));
        if(isAtAdminArea)
            return '';

        return 'menu.toolbar.actions.myProfile.text';
    }

    get canShowNormalToolbar() {
        return Authentication.isLogged
    }
}
