import { Injectable } from '@angular/core';
import { Params, Router } from "@angular/router";

import { Observable, Subject } from "rxjs";

import { HttpClient, HttpResponse } from "@angular/common/http";
import { take } from "rxjs/operators";
import { Translate } from "../../shared/helpers/translate";
import { environment } from "../../../environments/environment";
import { GlobalLoadingService } from "../../shared/components/default/global-loading/global-loading.service";
import { AuthenticationService } from "../../core/authentication/authentication.service";
import { LayoutHomeService } from "../../shared/components/custom/layout-home/layout-home.service";

@Injectable({
    providedIn: 'root'
})
export class SignInService {
    onLogout = new Subject();

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private homeService: LayoutHomeService,
        private globalLoadingService: GlobalLoadingService,
        private authenticationService: AuthenticationService
    ) {
    }

    get isAtAdmin(){
        return this.router.url.includes(Translate.value("routes.allRoutePrefix.admin"));
    }

    get loginUrl(): string {
        if (this.isAtAdmin)
            return Translate.value('routes.admin.login.path');

        return Translate.value('routes.login.path');


    }

    isAtLogin(): boolean {
        return this.router.url.includes(this.loginUrl.replace('/', ''));
    }

    login(parameters: Params): Observable<HttpResponse<any>> {
        const url  = this.isAtAdmin ? `${environment.api}/admin_auth/sign_in` : `${environment.api}/auth/sign_in`
        return this.httpClient
            .post<HttpResponse<any>>(url, {...parameters}, {observe: 'response'}).pipe(take(1));
    }

    logout(): void {
        this.onLogout.next(null);
        this.globalLoadingService.send({
            show: true,
            message: Translate.value('globalLoading.logout', {}, {projectName: this.homeService.projectName})
        });

        const url  = this.isAtAdmin ? `${environment.api}/admin_auth/sign_out` : `${environment.api}/auth/sign_out`

        setTimeout(() => {
            this.httpClient
                .delete<HttpResponse<any>>(url)
                .pipe(take(1))
                .subscribe(() => {
                    this.authenticationService.resetAuthentication();
                    this.globalLoadingService.send({show: false});

                });
        }, 500);
    }
}
