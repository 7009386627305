import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

import { Observable, tap } from "rxjs";
import { take } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { environment } from "../../../environments/environment";
import { Authentication } from "./authentication";
import { AppService } from "../../app.service";
import { GlobalLoadingService } from "../../shared/components/default/global-loading/global-loading.service";
import { Translate } from "../../shared/helpers/translate";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { userActions } from "../../shared/store/actions";
import { BaseSubscriber } from "../../shared/base/base-subscriber";

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService extends BaseSubscriber {
    validateTokenObservable: Observable<any> | null;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private route: ActivatedRoute,
        private root: AppService,
        private globalLoadingService: GlobalLoadingService,
        private store: Store
    ) {
        super();
        this.subscribe(Authentication.userChangedSuject.pipe(tap(resp => {
            if (resp)
                this.store.dispatch(userActions.setCurrent({payload: resp}));
        })))
    }

    get loginUrl(): string {
        if (this.router.url.includes(Translate.value("routes.allRoutePrefix.admin")))
            return Translate.value('routes.admin.login.path', {initialValue: ''});


        return Translate.value('routes.login.path', {initialValue: ''});
    }

    validateToken(isAdmin: boolean): void {

        if (!this.validateTokenObservable) {
            this.globalLoadingService.send({show: true, message: 'globalLoading.authentication'});

            if (isAdmin)
                this.validateTokenObservable = this.httpClient.get<any>(`${environment.api}/admin_auth/validate_token`).pipe(take(1));
            else
                this.validateTokenObservable = this.httpClient.get<any>(`${environment.api}/auth/validate_token`).pipe(take(1));


            this.validateTokenObservable.subscribe(response => {
                Authentication.setUser(response.admin || response.user);
                this.validateTokenObservable = null;

                setTimeout(() => {
                    this.shouldBeRedirect();
                    this.globalLoadingService.send({show: false, message: 'globalLoading.authentication'});
                }, 1000)
            }, () => {
                setTimeout(() => {
                    this.globalLoadingService.send({show: false, message: 'globalLoading.authentication'});
                }, 1000)
            });
        }
    }

    resetAuthentication(): void {
        let extraParams: NavigationExtras = {};

        this.route.queryParams.subscribe(params => {
            const nextPathParam = Translate.value('routes.login.nextPath');
            const nextPathValue = params?.[nextPathParam];

            if (nextPathValue && Auxiliary.removeUrlParameters(this.router.url) === this.loginUrl)
                extraParams = {queryParams: {[nextPathParam]: nextPathValue}};
        });

        Authentication.resetHeaders();
        Authentication.setUser(null);

        this.router.navigate([this.loginUrl], {queryParams: {}});

    }


    private shouldBeRedirect(): void {
        setTimeout(() => {
            const url = Auxiliary.removeUrlParameters(this.router.url.trim());
            const loginRoute = Translate.value('routes.login.path').replace('/', '');
            const queryParamsHandling = 'merge';

            if (
                !url ||
                url === '/' ||
                url.replace('/', '').startsWith(loginRoute)
            ) {
                this.router.navigate([this.root.initialUrl], {queryParamsHandling});
            }
        }, 100);

    }

}
