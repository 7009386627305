import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { userActions } from "../actions";
import { tap } from "rxjs";
import { MandatoryDialogsService } from "../../services/mandatory-dialogs/mandatory-dialogs.service";

@Injectable({
	providedIn: 'root'
})
export class UserEffects {

	verifyForceChangePassword = createEffect(() => { return this.actions.pipe(
		ofType(userActions.setCurrent),
		tap(() => this.mandatoryDialogs.verifyDialogs())
	) }, {dispatch: false})

	constructor(
		private mandatoryDialogs: MandatoryDialogsService,
		private actions: Actions
	) {
	}
}
