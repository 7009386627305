import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    OnDestroy,
    ViewChild,
    AfterViewInit,
    ElementRef
} from '@angular/core';
import { FormGroup } from "@angular/forms";

import { debounceTime, startWith } from "rxjs/operators";
import { fromEvent, Subscription } from "rxjs";
import { FormService } from "./form.service";
import { DialogService } from "../dialog/dialog.service";
import { FormButton } from "../../../models/form-button";
import { Auxiliary } from "../../../helpers/auxiliary";

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss'],
    preserveWhitespaces: false
})
export class FormComponent implements OnInit, OnDestroy, AfterViewInit{
    @ViewChild('dialogContent') dialogContent: ElementRef;
    @Input() principalTitle = '';
    @Input() subTitle = '';
    @Input() isDialog = false;
    @Input() form: FormGroup;
    @Input() onlyHead = false;
    @Output() parentOnSubmit: EventEmitter<FormGroup> = new EventEmitter();

    hasScroll = false;

    resetDialog: FormButton = {
        color: 'primary',
        theme: 'basic',
        type: 'button',
        condition: () => true,
        click: () => this.dialogService.close(),
        text: 'form.actions.close.text'
    };

    reset: FormButton = {
        color: 'accent',
        theme: 'stroked',
        type: 'button',
        condition: () => false,
        click: () => FormService.clearForm(this.form as FormGroup),
        text: 'form.actions.clear.text'
    };

    @Input('resetDialog')
    set setResetDialog(reset: FormButton){
        setTimeout(() => {
            if(reset && this.isDialog) this.resetDialog = Auxiliary.assignAllObjects(this.reset, reset) as FormButton;
        }, 50);
    }

    @Input('reset')
    set setReset(reset: FormButton){
        if(reset) this.reset = Auxiliary.assignAllObjects(this.reset, reset) as FormButton;
    }

    @Input('save')
    set setSave(save: FormButton){
        setTimeout(() => {
            if(save)
                this.save = Auxiliary.assignAllObjects(
                    {
                        ...this.save,
                        color: 'primary',
                        theme: this.isDialog ? 'basic' : 'raised',
                        type: 'submit',
                        condition: () => true,
                        click: () => {},
                        text: 'form.actions.save.text',
                    },
                    save
                ) as FormButton;
        }, 50);
    }

    save: FormButton = {} as FormButton;

    private subscriptions: Subscription[] = [];
    private alreadySubmitted = false;

    constructor(private dialogService: DialogService){}

    ngOnInit(): void{
        this.subscriptions.push(
            this.form?.valueChanges?.pipe(startWith(this.verifyDisabledSave())).subscribe(() => this.verifyDisabledSave()) as Subscription,
            FormService.watchErrorsInForm().subscribe(errors => {
                const keysInForm = Object.keys(errors).filter(error => this.form?.controls[error]);

                for(const key of keysInForm){
                    const value = errors?.[key];
                    const error = Auxiliary.isArray(value) ? value?.[0] : value;

                    if(error){
                        const field = this.form?.get(key);

                        field?.setErrors({ customError: error });
                        field?.markAsTouched();
                    }
                }
            })
        );

        this.setFinalReset();
    }

    ngAfterViewInit(): void{
        this.calcHasScroll(1000);
        this.watchResize();
    }

    onSubmit(): void{
        this.alreadySubmitted = true;
        this.parentOnSubmit.emit(this.form);
    }

    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    private watchResize(): void{
        this.subscriptions.push(
            fromEvent(this.dialogContent?.nativeElement || window, 'resize').pipe(debounceTime(1000)).subscribe(() => this.calcHasScroll()),
            fromEvent(window, 'resize').pipe(debounceTime(1000)).subscribe(() => this.calcHasScroll())
        );
    }

    private calcHasScroll(ms = 0): void{
        setTimeout(() => {
            this.hasScroll = this.dialogContent?.nativeElement?.scrollHeight !== this.dialogContent?.nativeElement?.clientHeight;
        }, ms);
    }

    private verifyDisabledSave(): void{
        if(Auxiliary.isUndefined(this.save?.disabled) || Auxiliary.isNull(this.save?.disabled))
            this.save.disabled = () => FormService.disableSave(this.form as FormGroup);
    }

    private setFinalReset(): void{
        if(this.isDialog) this.reset = Auxiliary.assignAllObjects(this.reset, this.resetDialog) as FormButton;
    }
}
