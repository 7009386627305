<mat-form-field [appearance]="appearance"
                [hintLabel]="hintLabel | translate"
                class="date-range"
                [formGroup]="form"
                [floatLabel]="floatLabel">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-date-range-input [required]="required"
                          [separator]="separator"
                          [dateFilter]="filter"
                          [rangePicker]="dateRange">
        <input matStartDate
               [required]="required"
               [attr.aria-label]="initPlaceholder | translate"
               [name]="startName"
               autocomplete="chrome-off"
               #startInput
               [appFormDate]="textMaskConfig"
               (dateInput)="dateServ.onChange(startInput, formatDate, startField); skipToNextInput()"
               (dateChange)="dateServ.onChange(startInput, formatDate, startField); skipToNextInput()"
               aria-label=""
               [formControlName]="startName"
               [placeholder]="initPlaceholder | translate">
        <input matEndDate
               [attr.aria-label]="finalPlaceholder | translate | lowercase"
               autocomplete="off"
               aria-label=""
               #endInput
               [appFormDate]="textMaskConfig"
               (dateInput)="dateServ.onChange(endInput, formatDate, endField)"
               (dateChange)="dateServ.onChange(endInput, formatDate, endField)"
               [required]="required"
               [name]="endName"
               [formControlName]="endName"
               [placeholder]="finalPlaceholder | translate | lowercase">
    </mat-date-range-input>
    <mat-datepicker-toggle matSuffix [for]="dateRange"></mat-datepicker-toggle>
    <mat-date-range-picker #dateRange
                           [startView]="startView"
                           (yearSelected)="onYearSelected($event, dateRange, date)"
                           (monthSelected)="onMonthSelected($event, dateRange, date)">
    </mat-date-range-picker>
    <mat-icon aria-hidden="true" matSuffix *ngIf="icon">{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(startField) || formsHelper.isInvalidField(endField)">
        {{ (formsHelper.getErrorMessage(startField) | async) || (formsHelper.getErrorMessage(endField) | async) }}
    </mat-error>
</mat-form-field>
