export const ACCOUNTS_TRANSLATE = {

    accounts: {

        messages: {
            create: "Conta criada com sucesso",
            update: "Conta atualizada com sucesso",
            delete: "Conta deletada com sucesso",
            recover: "Conta recuperada com sucesso"
        },
        list: {
            filters: {
                fields: {
                    id: {
                        label: "ID",
                        placeholder: "Informe o ID",
                        url: "id"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome",
                        url: "nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail",
                        url: "email"
                    },
                    active: {
                        label: "Situação",
                        placeholder: "Selecione a situação",
                        url: "situacao"
                    }
                }
            },
            dialog: {
                delete: {
                    title: "Inativar conta",
                    body: "Tem certeza que deseja inativar a conta com o ID {{id}}",
                    save: {
                        text: "Sim, inativar"
                    },
                    back: {
                        text: "Voltar"
                    },

                }
            },
            table: {
                columns: {
                    id: "ID",
                    name: "Nome"
                }
            }
        },
        tabs: {
            general: "Geral",
            text: "Textos",
            users: "Usuários",
            asks: "Perguntas",
			logo: "Logo"
        },


        save: {
            form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe aqui o nome"
                    },
                    uuid: {
                        label: "UUID",
                        placeholder: "Informe aqui o UUID da conta"
                    },
                    welcomeTerms: {
                        label: "Termos de uso",
                        placeholder: "Informe aqui os termos de uso"
                    },
                    welcomeMessage: {
                        label: "Mensagem de boas vindas",
                        placeholder: "Informe aqui a mensagem de boas vindas"
                    },
                    choiceHelpText: {
                        label: "Mensagem para o botão \" Não sabe qual escolher?\" ",
                        placeholder: "Informe aqui a mensagem"
                    },
                    helpText: {
                        label: "Mensagem para o botão \" Ajuda\" ",
                        placeholder: "Informe aqui a mensagem"
                    },
                    generalChannelUrl: {
                        label: "URL da denúncia",
                        placeholder: "Informe aqui a URL da denúncia"
                    },
                    womenChannelUrl: {
                        label: "URL do canal da mulher",
                        placeholder: "Informe aqui a URL do canal da mulher"
                    },
                    showGeneralChannel: {
                        label: "Exibir canal geral"
                    },
                    showWomenChannel: {
                        label: "Exibir canal da mulher"
                    },

                    categoryIsOptional:{
                        label: "Categoria opcional",
                        placeholder: "Enquanto essa opção estiver marcada, o denunciante não tem a obrigação de informar a categoria"
                    },
					logo: {
						label: "Logo",
						noResultsLabel: "Nenhuma imagem adicionada",
						noResultsDescription: "",
						textButton: "Adicionar logo",
						changeTextButton: "Alterar logo",
						hints: {
							first: "A proporção ideal para a logo é de 1500 x 700 (largura x altura)",
							second: "Os formatos aceitos são: .JPG, .JPEG, .PNG, .WEBP, .GIF"
						}
					}
                }
            }
        },

        asks: {
            messages: {
                create: "Pergunta criada com sucesso",
                update: "Pergunta atualizada com sucesso",
                delete: "Pergunta deletada com sucesso",
                recover: "Pergunta recuperada com sucesso"
            },

            list: {
                dialog: {
                    delete: {
                        title: "Remover pergunta",
                        body: "Tem certeza que deseja remover a pergunta com o ID {{id}}",
                        save: {
                            text: "Sim, remover"
                        },
                        back: {
                            text: "Voltar"
                        },

                    }
                },
                listActions: {
                    options: {
                        text: "Opções de resposta"
                    }
                },
                columns: {
                    title: "Título",
                    id: "ID",
                    required: "Obrigatório"
                }
            },

            create: {
                title: "Adicionar pergunta"
            },
            update: {
                title: "Editar pergunta"
            },

            fields: {
                title: {
                    label: "Nome do campo",
                    placeholder: "Informe aqui o nome do campo"
                },
                required: {
                    label: "Campo obrigatório"
                }
            },

            options: {
                messages: {
                    create: "Opção adicionada com sucesso",
                    update: "Opção atualizada com sucesso",
                    delete: "Opção deletada com sucesso",
                    recover: "Opção recuperada com sucesso"
                },
                create: {
                    title: "Adicionar opção"
                },
                update: {
                    title: "Editar opção"
                },
                fields: {
                    title:{
                        label: "Título da opção",
                        placeholder: "Informe aqui o título da opção"
                    }

                },
                list: {
                    label: "Opções",
                    columns: {
                        id: "ID",
                        title: "Título"
                    },
                    dialog: {
                        delete: {
                            title: "Remover opção",
                            body: "Tem certeza que deseja remover a opção com o ID {{id}}",
                            save: {
                                text: "Sim, remover"
                            },
                            back: {
                                text: "Voltar"
                            },

                        }
                    },
                },
                save: {
                    fields: {
                        title: {
                            label: "Título",
                            placeholder: "Informe aqui o título da opção"
                        }
                    }
                }
            }
        }

    }
}
