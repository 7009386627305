import { createReducer, on } from '@ngrx/store';

import { menuActions } from '../actions';
import { Menu } from "../../models/menu";

export type MenuDisplayState = 'open' | 'closed';

export interface State {
    loading: boolean;
    displayState: MenuDisplayState;
    snapshot: Menu[];
}

export const initialState: State = {
    loading: false,
    displayState: "open",
    snapshot: []
};

export const reducer = createReducer(
    initialState,
    on(menuActions.setSnapshot, (state, {payload}) => ({...state, menus: payload})),
    on(menuActions.setDisplayState, (state, {payload}) => ({...state, displayState: payload})),
    on(menuActions.toggle, state => {
        let displayState: MenuDisplayState = 'open';
        if (state.displayState === 'open') displayState = 'closed';

        return {
            ...state,
            displayState
        };
    }),
);


