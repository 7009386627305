<a tabindex="0" *ngIf="!isInternal && !isNotALink; else internal" [href]="link" [target]="target" [rel]="rel" class="link">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #internal>
    <a tabindex="0" *ngIf="!isNotALink; else notLink" [routerLink]="link" [target]="target" [rel]="rel" class="link">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </a>
</ng-template>

<ng-template #notLink>
    <span (click)="!disabled && click($event)"
          (keydown.enter)="!disabled && click($event)"
          role="button"
          [ngClass]="{'link--disabled': disabled}"
          class="link"
          [attr.aria-disabled]="disabled ? disabled : null"
          tabindex="0">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </span>
</ng-template>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
