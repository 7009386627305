import { Injectable } from "@angular/core";
import { DialogService } from "../../shared/components/default/dialog/dialog.service";
import { ForceChangePasswordComponent } from "./force-change-password.component";
import { Authentication } from "../../core/authentication/authentication";

@Injectable({
	providedIn: "root",
})
export class ForceChangePasswordDialogService {
	constructor(
		private dialog: DialogService,
	) {
	}

	shouldOpen() {
		return !!Authentication.user?.forceChangePassword;
	}

	open() {
		this.dialog.open({
			component: ForceChangePasswordComponent,
			configurations: {
				closeOnNavigation: false,
				disableClose: true
			}
		})
	}
}
