export const HOME_TRANSLATE = {
    home: {
        actions: {
            goToLogin: {
                text: "Área do cliente"
            },

            goToJoinin: {
                text: "Quero conhecer"
            },
            followUpComplaints: {
                text: "Acompanhar denúncia"
            }

        }
    }
}
