export const MY_PROFILE_TRANSLATE = {
    myProfile: {
        messages: {
            changePassword: "Senha alterada com sucesso"
        },
        read: {
            tabs: {
                data: "Geral",
                changePassword: "Alterar senha"
            }
        },
        save: {
            form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail"
                    },
                    currentPassword: {
                        label: "Senha atual",
                        placeholder: "Informe a senha atual"
                    },
                    password: {
                        label: "Nova senha",
                        placeholder: "Informe a nova senha"
                    },
                    passwordConfirmation: {
                        label: "Confirmar nova senha",
                        placeholder: "Confirme a nova senha"
                    }
                }
            }
        }

    }
}