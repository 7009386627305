<mat-toolbar color="primary"
             *ngIf="isOpen"
             class="toolbar mat-elevation-z3">
    <mat-toolbar-row class="toolbar__row">


        <div class="toolbar__menu"
             *ngIf="canShowNormalToolbar"
        >
            <div class="toolbar__space-menu toolbar__info">
                <app-avatar class="toolbar__space-small-menu"
                            [link]="null | call: urlProfile"
                            [tooltip]="null | call: avatarProfileTooltip"
                            text="menu.toolbar.actions.myProfile.text">
                </app-avatar>
                <h1 class="toolbar__title-page">{{user.name}}</h1>
            </div>

            <div class="toolbar__actions" *appVar="actions$ | async as actions">
                <div class="actions__left">
                    <ng-container *ngFor="let action of actions.left">
                        <app-form-button *ngIf="action.show"
                                         [icon]="action.icon"
                                         (click)="onActionClick(action)"
                                         [badge]="executeBadge(action.badge)"
                                         [target]="action.target"
                                         [href]="action.href"
                                         [color]="action.color"
                                         [theme]="action.theme"
                                         [svgIcon]="action.svgIcon"
                                         [classes]="action.classes"
                                         class="toolbar__space-small-menu"
                                         [tooltip]="action.tooltip | translate: action?.translationParams"
                                         [text]="action.text | translate: action?.translationParams">
                        </app-form-button>
                    </ng-container>
                </div>
            </div>
        </div>
    </mat-toolbar-row>
</mat-toolbar>
