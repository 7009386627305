import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";

import { take } from "rxjs/operators";

import { TranslateService } from "@ngx-translate/core";
import { Observable, Subject } from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class TabsService {
    tabURLName = '';

    private updatePaginationSubject = new Subject<boolean>();
    private updateTabSubject = new Subject<number>();

    constructor(
        private activatedRoute: ActivatedRoute,
        private translateService: TranslateService
    ){
        this.translateService.get("tabs.urlName").subscribe(translation => this.tabURLName = translation);
    }

	changeCurrentTab(tab: number){
		this.updateTabSubject.next(tab)
	}

    getCurrentTab(): number{
        let tab = 0;

        this.activatedRoute.queryParams.pipe(take(1)).subscribe(parametres => tab >= 0 ? tab = parametres[this.tabURLName] : tab = 0);

        return Number(tab);
    }

    getParametersOnURL(): Params{
        const parameters: Params = {};
        const tabValueOnURL = this.getCurrentTab();

        if(this.isThereTab(tabValueOnURL)) parameters[this.tabURLName] = tabValueOnURL;

        return parameters;
    }

    isThereTab(tab: number): boolean{
        return tab >= 0;
    }

    watchUpdatePagination(): Observable<boolean>{
        return this.updatePaginationSubject.asObservable();
    }

    watchUpdateTab(): Observable<number>{
        return this.updateTabSubject.asObservable();
    }

    sendUpdatePagination(value: boolean): void{
        return this.updatePaginationSubject.next(value);
    }
}
