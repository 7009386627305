
<mat-sidenav-container class="filters">
	<mat-sidenav #filters
				 class="filters__bar"
				 [mode]="mode"
				 position="end">
		<form autocomplete="off" class="filters__form" [formGroup]="form" (ngSubmit)="search()">
			<div class="filters__head">
				<h4 class="filters__title">{{ 'filters.title' | translate }}</h4>
				<app-form-button icon="clear"
								 color="primary"
								 theme="icon"
								 type="button"
								 [click]="close.bind(this)"
								 tooltip="filters.actions.close.text"
								 text="filters.actions.close.text">
				</app-form-button>
			</div>
			<div class="filters__body" *ngIf="fields.length">
				<div *ngFor="let field of fields">
					<app-form-input [label]="field.label"
									*ngIf="field.model === 'input' && !(field | canShow | async)"
									[type]="field.type"
									[placeholder]="field.placeholder"
									[mask]="field.mask"
									[form]="form"
									[uppercase]="field.uppercase || false"
									[icon]="field.icon"
									[dropSpecialCharacters]="field.dropSpecialCharacters"
									[leadZeroDateTime]="field.leadZeroDateTime"
									[hint]="field.hint"
									[maxlength]="field.maxlength"
									[name]="field.name">
					</app-form-input>
					<app-form-datepicker [label]="field.label"
										 [name]="field.name"
										 [form]="form"
										 (onMonthSelect)="onMonthSelect($event, field)"
										 [startView]="field.startView || 'month'"
										 [formatDate]="field.formatDate"
										 [filter]="field.filter"
										 *ngIf="field.model === 'datepicker' && !(field | canShow | async)"
										 [placeholder]="field.placeholder">
					</app-form-datepicker>
					<app-form-date-range [startName]="field.startName"
										 [endName]="field.endName"
										 [form]="form"
										 [formatDate]="field.formatDate"
										 [filter]="field.filter"
										 *ngIf="isModelDateRange(field)"
										 [label]="field.label">
					</app-form-date-range>
					<app-form-autocomplete [label]="field.label"
										   [service]="field.service"
										   [options]="field.options"
										   [sendProperty]="field.sendProperty"
										   [nameProperty]="field.nameProperty"
										   *ngIf="field.model === 'autocomplete' && !(field | canShow | async)"
										   [placeholder]="field.placeholder"
										   [form]="form"
										   [perPage]="field.perPage"
										   [returnName]="field.returnName"
										   [sortProperty]="field.sortProperty"
										   [sortDirection]="field.sortDirection"
										   [paramsSubject]="field.paramsSubject"
										   [params]="getParams(field) || {}"
										   [loadBefore]="field.loadBefore"
										   [forceGet]="field.forceGet"
										   (externalOptions)="onLoadOptions($event, field)"
										   [method]="field.method"
										   [name]="field.name">
					</app-form-autocomplete>
					<app-form-select [label]="field.label"
									 [nothing]="field.nothing"
									 [sendProperty]="field.sendProperty"
									 [nameProperty]="field.nameProperty"
									 [returnName]="field.returnName"
									 *ngIf="field.model === 'select' && !(field | canShow | async)"
									 [perPage]="field.perPage"
									 [service]="field.service"
									 [options]="field.options"
									 [multiple]="field.multiple"
									 [method]="field.method"
									 [placeholder]="field.placeholder"
									 [form]="form"
									 [name]="field.name">
					</app-form-select>

					<app-form-chips [label]="field.label"
									[sendProperty]="field.sendProperty || 'id'"
									[nameProperty]="field.nameProperty || 'name'"
									[returnName]="field.returnName"
									*ngIf="field.model === 'chips' && !(field | canShow | async)"
									[perPage]="field.perPage"
									[loadBefore]="true"
									[service]="field.service"
									[method]="field.method"
									[placeholder]="field.placeholder"
									[form]="form"
									[name]="field.name">
					</app-form-chips>
				</div>
			</div>
			<app-form-actions class="filters__actions"
							  [secondaryAction]="clear"
							  [primaryAction]="save">
			</app-form-actions>
		</form>
	</mat-sidenav>
	<mat-sidenav-content class="filters__content">
		<app-menu-breadcrumb></app-menu-breadcrumb>
<!--		<app-quick-filters *ngIf="fields.length"></app-quick-filters>-->
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
