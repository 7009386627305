import { MediaTypesEnum } from "../enums/media-types.enum";
import { FileInfo } from "../models/file-info";


export class FileHelper {
	static OVER_FILE_SIZE = 200000000;

	static getFileInfo<T=any>(
		file: {
			src: string;
			mimeType?: string;
			type?: MediaTypesEnum;
			identifier?: number | string;
			source?: File;
			size?: number;
			name?: string;
			data?: T;
		}
	) {
		const type = !!file.type ? file.type : this.getFileTypeByMime(file.mimeType ?? '');
		const name = file.name ?? file.mimeType?.replace(/\//, '.') ?? "";

		const extension = this.getFileExtension(name);

		const info: FileInfo<T> = {
			identifier: file.identifier,
			name,
			comment: "",
			extension,
			type,
			size: file.size,
			target: file.source,
			data: file.data,
			src: file.src,
		};


		return info;
	}

	static fileToFileInfo(file: File) {
        return this.getFileInfo({
			mimeType: file.type,
			src: FileHelper.getFileSrc(file),
			name: file.name,
			size: file.size,
			source: file
		});

    }

	static isOverFilesSizes(sizes: number[]){
		const allSize = sizes.reduce((prev, current)=> prev + current, 0);
		return allSize > this.OVER_FILE_SIZE;
	}

	static async createFileBySrc(src: string, filename?: string) {
		const blob = await this.getBlobBySrc(src);
		const { type } = blob;
		const name = filename ?? blob.type.replace(/\//, '.');

        return new File([blob], name, { type });


	}

	static async createFilesBySrcs(options: {src: string; filename?: string}[]) {
		const files = options.map(({src, filename}) => this.createFileBySrc(src, filename));

		return await Promise.all(files);
	}

	static async getAspectRatio(src: string, type: MediaTypesEnum): Promise<number> {
		const types = MediaTypesEnum;

        const videoAspectRatio = (srcUrl: string): Promise<number> =>
            (new Promise((resolve) => {
                const video = document.createElement('video');
                video.src = srcUrl;

                video.onloadeddata = (event: any) => {
                    const { videoWidth, videoHeight } = event.path[0] as HTMLVideoElement;
                    resolve(videoWidth / videoHeight);
                };

                video.onerror = (err) => { throw err; };
            }));

        const imageAspectRatio = (srcUrl: string): Promise<number>  =>
            (new Promise((resolve) => {
                const img = document.createElement('img');
                img.src = srcUrl;

                img.onload = (event: any) => {
                    if(!event?.path[0]) return;

                    const { naturalWidth, naturalHeight } = event.path[0] as HTMLImageElement;
                    resolve(naturalWidth / naturalHeight);
                };

                img.onerror = (err) => { throw err; };
            }));

		try {
			switch (type) {
				case types.IMAGE:
					return await imageAspectRatio(src);
				case types.VIDEO:
					return await videoAspectRatio(src);
				default:
					return 0;
			}
		} catch (error) {
			return 0;
		}



	}


	static async getBlobBySrc(src: string) {
		return await fetch(src, {mode: 'no-cors'}).then(r => r.blob());
	}

	static getFileType(file: File) {
		return this.getFileTypeByMime(file.type);
	}

	static getFileTypeByMime(mime: string) {
		if (mime.startsWith("image")) return MediaTypesEnum.IMAGE;
		if (mime.startsWith("video")) return MediaTypesEnum.VIDEO;
		if (mime.startsWith("application/pdf")) return MediaTypesEnum.PDF;

		return MediaTypesEnum.ATTACHMENT;
	}

	static getFileSrc(file: File): string {
		const url = window.URL || window.webkitURL;

		return url.createObjectURL(file);
	}

	static getFileExtension(fileName: string) {
		const parts = fileName.split('.');
		return parts[parts.length - 1].toUpperCase();
	}

	static getFormattedFileSize(bytes: number, si = false, dp = 1) {
		const thresh = si ? 1000 : 1024;

		if (Math.abs(bytes) < thresh) {
			return bytes + ' B';
		}

		const units = si
			? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
			: ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
		let u = -1;
		const r = 10 ** dp;

		do {
			bytes /= thresh;
			++u;
		} while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);


		return bytes.toFixed(dp) + ' ' + units[u];
	}

	static isValidUrl(url: string): Promise<boolean>{
		return new Promise((resolve) => {
			fetch(url)
			.then(v => {
				if(v.status === 404) resolve(false);

				resolve(true);
			})
			.catch(()=> {
				resolve(false);
			});
		});
	}
}
