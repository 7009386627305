import { selectApp } from "./app";
import { PermissionsEnum } from "../../enums/permissions.enum";
import { createSelector } from "@ngrx/store";
import { User } from "../../../modules/users/models/user";


const hasPermission = (user: User, permissions: PermissionsEnum[]) => {
	return !!user?.permissionCodes.filter(perm => permissions.includes(perm)).length;
}

export const selectUserState = createSelector(
    selectApp,
    (state) => state.userState
);

export const selectCurrentUser = createSelector(
    selectUserState,
    (state) => state.current
);


export const selectHasPermissions = (permissions: PermissionsEnum[]) =>
    createSelector(selectCurrentUser, (current) => {
        if (!current) return false;

        if(current.permissionCodes)
            return hasPermission(current, permissions);
        else return true;
    });
