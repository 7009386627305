import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from "@angular/common/http";


import { RequestsInterceptor } from "./core/interceptors/requests/requests.interceptor";
import { SnackBarModule } from "./shared/components/default/snack-bar/snack-bar.module";

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        SnackBarModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RequestsInterceptor,
            multi: true
        }
    ]
})
export class AppRequestsModule {}
