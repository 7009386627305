import { Injectable } from '@angular/core';
import {
	ActivatedRouteSnapshot,
	CanActivate, Router,
	RouterStateSnapshot,
	UrlTree
} from '@angular/router';

import { Observable } from 'rxjs';

import { AuthenticationService } from "../../authentication/authentication.service";
import { Authentication } from "../../authentication/authentication";
import { Translate } from "../../../shared/helpers/translate";

@Injectable({
	providedIn: 'root',
})
export class RoutesGuard implements CanActivate {
	constructor(
		private authenticationService: AuthenticationService,
		private router: Router

	) {
	}
	canActivate(
		route: ActivatedRouteSnapshot, state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		const url = state.url;
		const validateTokenRequest = !Authentication.isLogged;
		const isAdminRoute = this.startWith(url, Translate.value('routes.allRoutePrefix.admin'));
		const isAccountRoute = this.startWith(url,Translate.value('routes.allRoutePrefix.account'));
		const isLogin = url.includes(Translate.value('routes.login.path'));

		if(validateTokenRequest && !isLogin && (isAccountRoute || isAdminRoute))
			this.authenticationService.validateToken(isAdminRoute);

		if(isLogin && Authentication.isThereAccessToken()){
			this.goToInitialPageAccess(isAdminRoute)
		}

		return true;
	}

	private startWith(url: string, routeToCompare: string){
		return url.replace('/', '').startsWith(routeToCompare);
	}

	private goToInitialPageAccess(isAdmin: boolean){
		if(isAdmin) this.router.navigate([Translate.value("routes.admin.accounts.path")])
		else this.router.navigate([Translate.value("routes.account.users.path")])
	}
}
