<div class="small-content">
    <app-icon *ngIf="icon || svgIcon"
                [badge]="badge"
                [icon]="icon"
                [svgIcon]="svgIcon"
                [classes]="iconClasses"
                [class]="text ? 'small-content__icon small-content__icon--with-text' : 'small-content__icon'">
    </app-icon>
    <span *ngIf="text" class="small-content__text">{{ text | translate }}</span>
</div>
