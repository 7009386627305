import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { RouterModule } from "@angular/router";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";

import { MaskPipe, NgxMaskModule } from "ngx-mask";
import { NgxCurrencyModule } from "ngx-currency";
import { TranslateModule } from "@ngx-translate/core";
import { QuillModule } from "ngx-quill";
import { MAT_COLOR_FORMATS, NgxMatColorPickerModule } from "@angular-material-components/color-picker";

import { FormComponent } from "./form.component";
import { FormInputComponent } from "./input/form-input.component";
import { FormAutocompleteComponent } from "./autocomplete/form-autocomplete.component";
import { FormSelectComponent } from "./select/form-select.component";
import { FormDatepickerComponent } from "./datepicker/form-datepicker.component";
import { FormDateRangeComponent } from './date-range/form-date-range.component';
import { FormFabComponent } from "./fab/form-fab.component";
import { FormButtonComponent } from "./button/form-button.component";
import { FormSmallContentComponent } from "./small-content/form-small-content.component";
import { FormActionsComponent } from "./actions/form-actions.component";
import { FormSlideToggleComponent } from "./slide-toggle/form-slide-toggle.component";
import { FormRadioComponent } from "./radio/form-radio.component";
import { FormRequiredLabelComponent } from "./required-label/form-required-label.component";
import { FormCheckboxComponent } from "./checkbox/form-checkbox.component";
import { FormSliderComponent } from "./slider/form-slider.component";
import { FormToggleGroupComponent } from "./toggle-group/form-toggle-group.component";
import { FormChipsComponent } from "./chips/form-chips.component";
import { FormButtonLoadingDirective } from "../../../directives/form-button-loading/form-button-loading.directive";
import { FormColorPickerComponent } from "./color-picker/form-color-picker.component";
import { FormDateDirective } from "../../../directives/form-date/form-date.directive";
import { FormTextareaComponent } from "./textarea/form-textarea.component";
import { FormCardSelectComponent } from "./card-select/form-card-select.component";
import { AngularMaterialModule } from "../../../../core/angular-material/angular-material.module";
import { RootTranslateModule } from "../../../pipes/root-translate/root-translate.module";
import { DirectivesModule } from "../../../directives/directives.module";
import { IconModule } from "../icon/icon.module";
import { ProgressSpinnerModule } from "../progress-spinner/progress-spinner.module";
import { WindowLoadingModule } from "../window-loading/window-loading.module";
import { NoResultsModule } from "../no-results/no-results.module";
import { Translate } from "../../../helpers/translate";
import { FormDateAdapterService } from "./form-date-adapter.service";
import { RootTranslatePipe } from "../../../pipes/root-translate/root-translate.pipe";
import { FormErrorComponent } from "./error/form-error.component";
import { FormQuillEditorComponent } from './quill-editor/form-quill-editor.component';
import {
	MatAutocompleteOptionsScrollModule
} from "../../../directives/mat-autocomplete-options-scroll/mat-autocomplete-options-scroll.module";
import { PipesModule } from "../../../pipes/pipes.module";


@NgModule({
    declarations: [
        FormComponent,
        FormInputComponent,
        FormAutocompleteComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        FormDateRangeComponent,
        FormFabComponent,
        FormButtonComponent,
        FormSmallContentComponent,
        FormActionsComponent,
        FormSlideToggleComponent,
        FormRadioComponent,
        FormRequiredLabelComponent,
        FormCheckboxComponent,
        FormSliderComponent,
        FormToggleGroupComponent,
        FormChipsComponent,
        FormButtonLoadingDirective,
        FormColorPickerComponent,
        FormDateDirective,
        FormTextareaComponent,
        FormCardSelectComponent,
        FormErrorComponent,
        FormQuillEditorComponent
    ],
    imports: [
        CommonModule,
        AngularMaterialModule,
        ReactiveFormsModule,
        FormsModule,
        RootTranslateModule,
        RouterModule,
        NgxCurrencyModule,
        DirectivesModule,
        IconModule,
        NgxMaskModule.forRoot({validation: false}),
        TranslateModule,
        ProgressSpinnerModule,
        NgxMatColorPickerModule,
        WindowLoadingModule,
        NoResultsModule,
        QuillModule,
		MatAutocompleteOptionsScrollModule,
		PipesModule
	],
    exports: [
        FormComponent,
        FormInputComponent,
        FormAutocompleteComponent,
        FormSelectComponent,
        FormDatepickerComponent,
        FormDateRangeComponent,
        FormFabComponent,
        FormButtonComponent,
        FormSmallContentComponent,
        FormActionsComponent,
        FormSlideToggleComponent,
        FormRadioComponent,
        FormRequiredLabelComponent,
        FormCheckboxComponent,
        FormSliderComponent,
        FormToggleGroupComponent,
        FormChipsComponent,
        FormColorPickerComponent,
        FormTextareaComponent,
        FormCardSelectComponent,
        FormErrorComponent,
        FormQuillEditorComponent
    ],
    providers: [
        {
            provide: MAT_COLOR_FORMATS,
            useValue: {display: {colorInput: 'hex6'}}
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: Translate.configurations.defaultLanguage
        },
        {provide: DateAdapter, useClass: FormDateAdapterService},
        {
            provide: MAT_DATE_FORMATS,
            useValue: {
                parse: {
                    dateInput: 'DD/MM/YYYY',
                },
                display: {
                    dateInput: 'DD/MM/YYYY',
                    monthYearLabel: 'MMM YYYY',
                    dateA11yLabel: 'LL',
                    monthYearA11yLabel: 'MMMM YYYY',
                },
            }
        },
        {
            provide: MaskPipe,
            useClass: MaskPipe
        },
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: {
                appearance: 'outline'
            }
        },
        CurrencyPipe,
        RootTranslatePipe
    ]
})
export class FormModule {
}
