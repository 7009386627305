import {Injectable} from '@angular/core';
import {HttpResponse} from "@angular/common/http";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import {TranslateService} from "@ngx-translate/core";
import { filter } from "rxjs/operators";

import { Translate } from "./shared/helpers/translate";
import { Authentication } from "./core/authentication/authentication";
import { GlobalLoadingService } from "./shared/components/default/global-loading/global-loading.service";
import { LayoutHomeService } from "./shared/components/custom/layout-home/layout-home.service";

@Injectable({
	providedIn: 'root'
})
export class AppService {
	color = '';
	backgroundColor = '';


	constructor(
		private translateService: TranslateService,
		private route: ActivatedRoute,
		private router: Router,
		private globalLoadingService: GlobalLoadingService,
		private homeServ: LayoutHomeService
	) {
		this.watchChangeRoutes();
	}

	get canISeeBreadcrumb(){
		return true;
	}

	get initialUrl(): string {
		const nextPath = this.route.snapshot.queryParams[Translate.value('routes.login.nextPath')];
		const path = Translate.value("routes.account.complaints.path");
		return nextPath ? nextPath : path;
	}

	initApplicationWithUser(response: HttpResponse<any>, isAdmin = false) {
			Authentication.setHeaders(Authentication.createHeadersByResponse(response));

			Authentication.setUser(response.body.admin || response.body.user);
			this.globalLoadingService.send({
				show: true,
				message: Translate.value('globalLoading.login', {}, {projectName: this.homeServ.projectName}),
				before: 1500,
			});

			setTimeout(() => {

				if(!isAdmin){
					this.router.navigateByUrl(this.initialUrl);
				}else{
					this.router.navigateByUrl(Translate.value("routes.admin.accounts.path"));
				}
			}, 1600)


	}

	dataContentPx(propSize: string, removeProps: string[]): string {
		try {
			const content = document.querySelector('[data-content]') as HTMLElement;
			const computedStyle = getComputedStyle(content);
			const paddingTopAndLeft = removeProps.reduce((acc, cur) =>
					// @ts-ignore
					acc + Number(computedStyle[cur].replace(/\D/g, ''))
				, 0);

			// @ts-ignore
			return `${content[propSize] - paddingTopAndLeft}px`;
		} catch (e) {
			return '0px';
		}
	}

	watchChangeRoutes(): void {
		this.router
			.events
			.pipe(filter(evt => evt instanceof NavigationEnd))
			.subscribe(() => {
					// if(!Authentication.isLogged) this.goToLogin();
			});
	}

	// private getTranslatedUrl(translation: string): string {
	// 	let url = '';
	//
	// 	this.translateService.get(translation).subscribe(path => url = path.replace('/', ''));
	//
	// 	return url;
	// }


	// private goToLogin(): void {
	// 	const urlLogin = this.getTranslatedUrl('routes.login.path');
	// 	const nextPath = this.getTranslatedUrl('routes.login.nextPath');
	// 	const pathname = window.location.pathname?.trim();
	// 	const nextUrl = pathname && pathname !== "/" ? pathname : null;
	// 	const params = {queryParams: {}};
	//
	// 	this.router.navigate([urlLogin], nextUrl ? params : {});
	// }
}
