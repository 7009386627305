<app-form-button [icon]="secondaryAction?.icon"
                     [color]="secondaryAction?.color"
                     [theme]="secondaryAction?.theme"
                     [type]="secondaryAction?.type"
                     [classes]="secondaryAction?.classes"
                     [click]="secondaryAction?.click"
                     [disabled]="secondaryAction?.disabled"
                     [tooltip]="secondaryAction?.tooltip"
                     *ngIf="secondaryAction?.condition && secondaryAction.condition()"
                     [text]="secondaryAction?.text">
</app-form-button>
<ng-content></ng-content>
<app-form-button [icon]="primaryAction?.icon"
                   [color]="primaryAction?.color"
                   [theme]="primaryAction?.theme"
                   [type]="primaryAction?.type"
                   [loading]="primaryAction?.loading"
                   [classes]="primaryAction?.classes"
                   [click]="primaryAction?.click"
                   *ngIf="primaryAction?.condition && primaryAction.condition()"
                   [disabled]="primaryAction?.disabled"
                   [tooltip]="primaryAction?.tooltip"
                   [text]="primaryAction?.text">
</app-form-button>
