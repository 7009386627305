import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    Input,
    OnDestroy,
    OnInit,
    Renderer2,
    ViewChild
} from '@angular/core';
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { MatChipInputEvent } from "@angular/material/chips";
import { FormControl, FormGroup } from "@angular/forms";
import { FloatLabelType, MatFormFieldAppearance } from "@angular/material/form-field";
import { MatOptionSelectionChange, ThemePalette } from "@angular/material/core";
import { MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Params } from "@angular/router";

import { fromEvent, Observable, of, Subscription } from "rxjs";
import { finalize, map, startWith, take } from "rxjs/operators";

import { FormService } from "../form.service";
import { Generic } from "../../../../models/generic";
import { FormChipsTypesEnum } from "../../../../enums/form-chips-types.enum";
import { Auxiliary } from "../../../../helpers/auxiliary";

@Component({
    selector: 'app-form-chips',
    templateUrl: './form-chips.component.html',
    styleUrls: ['./form-chips.component.scss']
})
export class FormChipsComponent implements OnInit, OnDestroy, AfterViewInit{
    @Input() removable = true;
    @Input() autofocus = false;
    @Input() selectable = true;
    @Input() addOnBlur = false;
    @Input() required = false;
    @Input() list: any[] = [];
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() nameProperty = 'name';
    @Input() sendProperty = 'id';
    @Input() iconProperty = 'icon';
    @Input() imageProperty = '';
    @Input() hint = '';
    @Input() loadBefore = true;
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() color: ThemePalette = undefined;
    @Input() label = '';
    @Input() placeholder = '';
    @Input() name = '';
    @Input() returnName = '';
    @Input() form: FormGroup;
    @Input() service: any;
    @Input() method = 'get';
    @Input() manualFilter = false;
    @Input() inputDisabled = false;
    @Input() sortProperty = 'name';
    @Input() sortDirection = 'asc';
    @Input() enviroment: Generic;
    @Input() page = 1;
    @Input() perPage = 25;
    @Input() params: Params = {};
    @Input() forceGet = false;
    @Input() icon = '';
    @Input() inputType = 'text';
    @Input() inputMode = 'text';
	@Input() cacheOptions: any[] = [];
    @Input() type: FormChipsTypesEnum|string = FormChipsTypesEnum.AUTOCOMPLETE;
    @ViewChild('formInput') formInput: ElementRef;
    @ViewChild('autocomplete') autocomplete: MatAutocomplete;
    @ViewChild('trigger') trigger: MatAutocompleteTrigger;
    filteredOptions: Observable<any[]>;
    formsHelper = FormService;
    field: FormControl;
    options: any[] = [];
    isLoading = false;
    debouceTimeout: any = 0;
    formInputValue = '';
    formInputLastValue = '';
    separatorKeysCodes: number[] = [];
	canGoToTheNextPage = true;
    readonly enum = FormChipsTypesEnum;

    private subscriptions: Subscription[] = [];

    constructor(private renderer: Renderer2, private changeDetectorRef: ChangeDetectorRef){}

    ngOnInit(): void{
        this.field = this.formsHelper.getField(this.form as FormGroup, this.name);
        this.required = Auxiliary.isBoolean(this.required) ? this.required : FormService.getRequired(this.field);

        this.preparateList();
        this.filterOptions();
        this.setSeparatorKeysCodes();
    }

    ngAfterViewInit(): void{
        this.subscriptions.push(
            fromEvent(document.querySelector('[data-content]') as HTMLElement, 'scroll')
                .subscribe(() => this.trigger?.updatePosition?.())
        );
    }

    add(event: MatChipInputEvent|MatAutocompleteSelectedEvent): void{
        switch(this.type){
            case FormChipsTypesEnum.INPUT:
                if(!(event instanceof MatAutocompleteSelectedEvent)){
                    const inputValue = event.value.trim();

                    if(inputValue){
                        this.list.push(inputValue);
                        this.resetInput();
                        this.setFieldValue();
                        this.form?.markAsDirty();
                    }
                }

                break;
            case FormChipsTypesEnum.AUTOCOMPLETE:
                if(event instanceof MatAutocompleteSelectedEvent){
                    const optionSelected = event?.option?.value;

                    if(optionSelected && !this.list.includes(optionSelected)){
                        this.list.push(optionSelected);
                        this.resetInput();
                        this.setFieldValue();
                        this.form?.markAsDirty();
                    }
                }

                break;
        }

        this.filterOptions();
    }

    remove(item: any): void{
        this.list.splice(this.list.indexOf(item), 1);
        this.setFieldValue();
        this.filterOptions();
        this.form?.markAsDirty();
        this.getOptions(true);
    }

    getOptions(forceGet = false, fromScroll = false): void{
        this.formInputValue = this.fieldValue;

        if(
            (Auxiliary.isEmptyString(this.formInputValue) ||
            Auxiliary.isEmptyString(this.formInputLastValue) ||
            this.formInputValue !== this.formInputLastValue || fromScroll) && !this.isLoading
        ){
			if(forceGet) {
				this.canGoToTheNextPage = true;
				this.options = [];
				this.filterOptions();
				this.page = 1;


			}
            this.isLoading = true;

            this.subscriptions.push(
                this.service?.[this.method]?.(
                        Auxiliary.createHttpParams(
                            Auxiliary.onlyValidParameters({
                                perPage: this.perPage,
                                search: this.manualFilter ? null : this.formInputValue,
                                sortDirection: this.sortDirection,
                                sortProperty: this.sortProperty,
                                page: this.page,
                                ...this.params
                            })
                        )
                    )
                    .pipe(
                        map((response: any) => this.returnName ? response[this.returnName] : response),
                        finalize(() => this.isLoading = false),
                        take(1)
                    )
                    .subscribe(
                        (options: any[]) => {
							const lastCount = options.length;
							this.canGoToTheNextPage = lastCount >= this.perPage;
                            this.formInputLastValue = this.formInputValue;
							this.setOptions(options);
                            this.addCacheOptions(options);

                            this.filterOptions();


                        }
                    )
            );
        }
    }

    filterOptions(): void{
        if(this.service){
            switch(this.type){
                case FormChipsTypesEnum.AUTOCOMPLETE:
                    this.filteredOptions = of(this.fieldValue).pipe(startWith(''), map(value => this.filterValue(value)));
                    this.changeDetectorRef.detectChanges();
                    break;
            }
        }
    }

    onInput(): void{
        if(this.manualFilter) this.filterOptions();
        else {
            const late = () => {
                clearTimeout(this.debouceTimeout);

                this.getOptions(true);
            };

            clearTimeout(this.debouceTimeout);

            this.debouceTimeout = setTimeout(late, 750);
        }
    }

    onFocus(): void{
        if(
            (
                !this.isLoading &&
                (!this.options.length || this.fieldValue)
            )
            ||
            this.forceGet
        ) this.getOptions(true);
    }

	onScroll() {
		if (this.canGoToTheNextPage) this.page++;

		this.getOptions(false, true);
	}

    openPanel(event: Event|MatOptionSelectionChange, trigger: MatAutocompleteTrigger): void{
        if(event instanceof Event) event.stopPropagation();

        setTimeout(() => trigger.openPanel(), 0);
    }

    selectedObjects(): any[]{
        let list: any[] = [];

        if(JSON.stringify(this.list) !== JSON.stringify(this.field?.value)) this.setFieldValue(this.field?.value);

        switch(this.type){
            case FormChipsTypesEnum.INPUT:
                list = this.list;
                break;
            case FormChipsTypesEnum.AUTOCOMPLETE:
                list = this.list.map(id => this.cacheOptions.find(item => item[this.sendProperty] === id));
                break;
        }

        return list;
    }

    setImage(item: any): string{
        try{
            return eval(`item.${this.imageProperty}`);
        } catch(error){
            return '';
        }
    }



    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

	disableOption(option: Generic){
		return this.list.includes(option[this.sendProperty]);
	}

    private get fieldValue(): string{
        return this.formInput?.nativeElement?.value || '';
    }

    private resetInput(): void{
        this.renderer.setProperty(this.formInput?.nativeElement, 'value', '');
    }

    private filterValue(value: any): any[] {
        return this.options
    }

    private preparateList(): void{
        switch(this.type){
            case FormChipsTypesEnum.INPUT:
                this.list = this.field.value || [];
                break;
            case FormChipsTypesEnum.AUTOCOMPLETE:
                if(this.loadBefore && this.service) this.getOptions();
                this.setFieldValue();
                break;
        }
    }

    private setFieldValue(list = this.list || [], markAsChanged = false): void{
        this.list = list || this.list || [];
        this.field?.setValue(this.list.map((item: any) => item));

        if(this.inputDisabled) this.field.markAsUntouched();
        else if(markAsChanged) this.formsHelper.markAsChanged(this.field as FormControl);
    }

    private addCacheOptions(options: any[]): void{
        options.forEach(item =>
            this.cacheOptions
                .map(option => JSON.stringify(option))
                .includes(JSON.stringify(item))
            ?
            null
            :
            this.cacheOptions.push(item)
        );
    }

    private setSeparatorKeysCodes(): void{
        this.separatorKeysCodes = this.type === FormChipsTypesEnum.INPUT ? [ENTER, COMMA] : [];
    }

	private setOptions(options: Generic[]) {
		const idArrays = this.options.map(item => item[this.sendProperty]);

		const filteredOptions = options.filter(item => !idArrays.includes(item[this.sendProperty]));

		this.options = [...this.options, ...filteredOptions];

	}

}
