import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { State } from "./reducers";
import { subscribeOnce } from "../rxjs-operators";

@Injectable({
	providedIn: "root",
})
export class StoreWrapperService<T=State> {
	constructor(private store: Store<T>) {
	}

	select<K>(mapFn: (state: T) => K): Observable<K>{
		return this.store.select<K>(mapFn);
	}

	selectSync<K>(mapFn: (state: T) => K): K | undefined{
		return subscribeOnce(this.select(mapFn));
	}
}
