import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-link',
    templateUrl: './link.component.html',
    styleUrls: ['./link.component.scss']
})
export class LinkComponent{
    @Input() link = '';
    @Input() target = '_self';
    @Input() rel = '';
    @Input() isInternal = false;
    @Input() isNotALink = false;
    @Input() disabled = false;
    @Input() click = (...args: any[]): any => args;
}
