import { ErrorHandler, Injectable, Injector } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";

import { fromEvent } from "rxjs";
import { TranslateService } from "@ngx-translate/core";

import { Generic } from "../../shared/models/generic";
import { SnackBarService } from "../../shared/components/default/snack-bar/snack-bar.service";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class ErrorsHandlerService implements ErrorHandler {
    private messages: Generic = {};
    private translateService: TranslateService = {} as TranslateService;

    constructor(
        private snackBarService: SnackBarService,
        private injector: Injector,
    ) {
        this.init();
    }

    static isKnownError(error: any): boolean {
        if (Auxiliary.isPermissionError(error)) return true;
        if (error instanceof HttpErrorResponse)
            return [0, 401, 403, 404, 405, 422, 500, 504].includes(error?.status) ||
                error?.error instanceof Blob ||
                Auxiliary.isModuleLoadingError(error);

        return false;
    }

    init(): void {
        setTimeout(() => {
            this.translateService = this.injector.get(TranslateService);

            this.translateService
                .get("errors")
                .subscribe(messagesError => this.messages = messagesError);

            fromEvent(window, 'offline').subscribe(() => this.errorConnection(this.messages.connectionDown));
            fromEvent(window, 'online').subscribe(() => this.snackBarService.create(this.messages.connectionComeBack, true));
        }, 2000);
    }

    handleError(error: any): void {
        if (Auxiliary.isModuleLoadingError(error)) {
            window.location.reload();
            this.errorConnection(this.messages.moduleImport);
        }
        if(environment.development) console.error(error);
        if (!ErrorsHandlerService.isKnownError(error)) {
            // const currentError = error?.originalError?.message || error?.originalError || error;
        }
    }

    private errorConnection(message: string): void {
        this.snackBarService.create(message, false, {
            data: {
                action: {
                    duration: SnackBarService.longTime,
                    data: {
                        action: {
                            text: "snackBar.actions.reload.text",
                            click: () => {
                                window.location.reload();
                            },
                            condition: () => true,
                        },
                    },
                }
            }
        });
    }
}
