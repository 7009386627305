import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar";
import { ThemePalette } from "@angular/material/core";

import { Observable, of } from "rxjs";
import { SnackBarTypesEnum } from "../../../enums/snack-bar-types.enum";
import { Generic } from "../../../models/generic";



@Component({
    selector: 'app-snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent{
    constructor(@Inject(MAT_SNACK_BAR_DATA) public snackBarData: Generic){}

    chooseColor(): Observable<ThemePalette>{
        return of(this.snackBarData.mode === SnackBarTypesEnum.SUCCESS ? 'primary' : 'warn');
    }
}
