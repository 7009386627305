import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FloatLabelType, MatFormFieldAppearance } from '@angular/material/form-field';
import { Params } from "@angular/router";
import { MatOptionSelectionChange } from "@angular/material/core";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

import { finalize, map, startWith, take } from 'rxjs/operators';
import { BehaviorSubject, fromEvent, Observable, of, Subject, Subscription } from 'rxjs';

import { FormService } from "../form.service";
import { Generic } from "../../../../models/generic";
import { Auxiliary } from "../../../../helpers/auxiliary";
import { Translate } from "../../../../helpers/translate";

@Component({
    selector: 'app-form-autocomplete',
    templateUrl: './form-autocomplete.component.html',
    styleUrls: ['./form-autocomplete.component.scss'],
    preserveWhitespaces: false
})
export class FormAutocompleteComponent implements OnInit, OnDestroy, AfterViewInit{
    @ViewChild('formInput') formInput: ElementRef;
    @ViewChild('trigger') trigger: MatAutocompleteTrigger;
    @Output() externalOptions = new EventEmitter<{ options: any[]; filteredOptions: Observable<any[]> }>();
    @Input() label = '';
    @Input() appearance: MatFormFieldAppearance = 'outline';
    @Input() placeholder = '';
    @Input() notFoundMessage = 'form.autocomplete.notFound';
    @Input() icon = '';
    @Input() hint = '';
    @Input() type = 'text';
    @Input() hintLabel = '';
    @Input() required: boolean;
    @Input() returnName = '';
    @Input() floatLabel: FloatLabelType = 'auto';
    @Input() service: any;
    @Input() options: Generic[] = [];
    @Input() isNotAsynchronous = false;
    @Input() isLoading = false;
    @Input() name = '';
    @Input() form: FormGroup;
    @Input() sortDirection = 'asc';
    @Input() autofocus = false;
    @Input() changeInFirstTime = false;
    @Input() params: Params = {};
    nameProperty = 'name';
    sendProperty = 'id';
    sortProperty = this.nameProperty;
    field: FormControl;
    formsHelper = FormService;
    filteredOptions: Observable<Generic[]>;
    value: Generic = {};
    debouceTimeout: any = 0;
    formInputValue = '';
    formInputLastValue = '';
    method = 'get';
    perPage = 25;
    loadBefore = true;
    paramsSubject: Subject<any>|BehaviorSubject<any>;
    forceGet = false;

    @Input('forceGet')
    set setForceGet(forceGet: boolean){
        if(Auxiliary.isBoolean(forceGet)) this.forceGet = forceGet;
    }

    @Input('loadBefore')
    set setLoadBefore(loadBefore: boolean){
        if(Auxiliary.isBoolean(loadBefore)) this.loadBefore = loadBefore;
    }

    @Input('paramsSubject')
    set setParamsSubject(paramsSubject: Subject<any>|BehaviorSubject<any>){
        if(paramsSubject) this.paramsSubject = paramsSubject;
    }

    @Input('perPage')
    set setPerPage(perPage: number){
        if(Auxiliary.isNumber(perPage)) this.perPage = perPage;
    }

    @Input('method')
    set setMethod(method: string){
        if(method) this.method = method;
    }

    @Input('nameProperty')
    set setNameProperty(nameProperty: string){
        if(nameProperty) this.nameProperty = nameProperty;
    }

    @Input('sendProperty')
    set setSendProperty(sendProperty: string){
        if(sendProperty) this.sendProperty = sendProperty;
    }

    @Input('sortProperty')
    set setSortProperty(sortProperty: string){
        if(sortProperty) this.sortProperty = sortProperty;
    }

    private subscriptions: Subscription[] = [];

    @Input() onSelect = (...args: any[]): any => args;

    ngOnInit(): void{
        this.field = this.formsHelper.getField(this.form as FormGroup, this.name);
        this.required = this.required || this.formsHelper.getRequired(this.field);
        this.isNotAsynchronous = !this.service;

        this.watchParamsSubject();
        this.init();
        this.filterOptions();
    }

    ngAfterViewInit(): void{
        this.subscriptions.push(
            fromEvent(document.querySelector('[data-content]') as HTMLElement, 'scroll')
                .subscribe(() => this.trigger?.updatePosition?.())
        );
    }

    init(): void{
        if(this.options === undefined) this.options = [];
        if(this.loadBefore && !this.isNotAsynchronous) this.get();

        this.watchChangeField();
    }

    get(): void{
        this.formInputValue = this.getFieldValue();

        if(
            Auxiliary.isEmptyString(this.formInputValue) ||
            Auxiliary.isEmptyString(this.formInputLastValue) ||
            this.formInputValue !== this.formInputLastValue
        ){
            this.isLoading = true;

            this.subscriptions.push(
                this.service?.[this.method]?.(
                        Auxiliary.createHttpParams(
                            Auxiliary.onlyValidParameters({
                                perPage: this.perPage,
                                search: this.formInputValue,
                                sortDirection: this.sortDirection,
                                sortProperty: this.sortProperty,
                                ...this.params
                            })
                        )
                    )
                    .pipe(
                        finalize(() => this.isLoading = false),
                        take(1),
                        map((response: Generic) => response[this.returnName])
                    )
                    .subscribe(
                        (options: Generic[]) => {
                            this.formInputLastValue = this.formInputValue;
                            this.options = options;

                            this.filterOptions();
                            this.sendExternalOptions();

                            // if(this.isValidValue()) this.field?.setValue(this.field?.value);
                        }
                    )
            );
        }
    }

    onFocus(): void{
        if(
            (
                !this.isNotAsynchronous &&
                !this.isLoading &&
                (!this.options.length || this.getFieldValue())
            )
            ||
            this.forceGet
        ) this.get();
    }

    displayNameProperty(optionId: number): string {
        const optionSelected: Generic = this.options.filter((item: Generic) => item[this.sendProperty] === optionId)[0];
        const name = optionSelected && optionSelected[this.nameProperty];

        return name ? Translate.value(name) : '';
    }

    ngOnDestroy(): void{
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    onInput(): void{
        if(!this.isNotAsynchronous){
            const late = () => {
                clearTimeout(this.debouceTimeout);

                this.get();
            };

            clearTimeout(this.debouceTimeout);

            this.debouceTimeout = setTimeout(late, 750);
        }
    }

    onSelectValue(event: MatOptionSelectionChange|null, option: Generic): void{
        this.onSelect(event, option, this.options);
    }

    clearField(){
        if(!this.disabledButton()) {
            this.field.setValue(null);
            this.formInput.nativeElement.value = "";
            this.get();
        }
    }

    disabledButton(){
        return this.field.disabled || !this.field.value;
    }

    private watchChangeField(): void{
        this.subscriptions.push(
            this.field
                ?.valueChanges
                ?.subscribe(value => {
                    if(this.changeInFirstTime && Auxiliary.isNumber(value)){
                        const item = this.options.find(option => option?.[this.sendProperty] === value);

                        if(item){
                            this.changeInFirstTime = false;
                            this.onSelectValue(null, item);
                        }
                    }
                }) as Subscription
        );
    }

    private getFieldValue(): string{
        return this.formInput?.nativeElement?.value || '';
    }

    private isValidValue(): boolean{
        return Auxiliary.isNumber(this.field?.value) && this.field?.value > 0;
    }

    private filterOptions(): void{
        if(this.isNotAsynchronous)
            this.filteredOptions = this.field?.valueChanges.pipe(startWith(''), map(value => this.filterValue(value)));
    }

    private filterValue(value: any): Generic[] {
        return this.options.filter((option: any) => (Auxiliary.equalLetters(
                option[this.nameProperty]
            ) as string).includes(
                Auxiliary.equalLetters(
                    Auxiliary.isObject(value) ? value[this.nameProperty] : value
                ) as string
            )
        );
    }

    private watchParamsSubject(): void{
        if(this.paramsSubject)
            this.subscriptions.push(
                this.paramsSubject.asObservable().subscribe(params => {
                    Object.assign(this.params, params);

                    if(this.loadBefore) this.get();
                })
            );
    }

    private sendExternalOptions(): void{
        this.externalOptions.next({
            options: this.options || [],
            filteredOptions: this.filteredOptions || of([])
        });
    }
}
