<div class="snack-bar">
    <p class="snack-bar__message"
       *ngIf="snackBarData.message"
       [ngClass]="{'snack-bar__message--only-text': !snackBarData.action || !snackBarData.action.condition()}">
        {{ snackBarData.message | translate }}
    </p>
    <app-form-button theme="basic"
                       [color]="(chooseColor() | async)"
                       type="button"
                       *ngIf="snackBarData.action && snackBarData.action.condition()"
                       classes="snack-bar__action mat-focus-indicator mat-button mat-button-base"
                       [click]="snackBarData?.action?.click.bind(this)"
                       [text]="snackBarData.action.text">
    </app-form-button>
</div>
