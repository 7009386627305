import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from "@angular/material/icon";


import { NoResultsComponent } from "./no-results.component";
import { RootTranslateModule } from "../../../pipes/root-translate/root-translate.module";

@NgModule({
    declarations: [
        NoResultsComponent
    ],
    imports: [
        CommonModule,
        MatIconModule,
        RootTranslateModule
    ],
    exports: [
        NoResultsComponent
    ]
})
export class NoResultsModule { }
