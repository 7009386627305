import { Injectable } from '@angular/core';
import { Translate } from "../../../helpers/translate";

// import { environment } from '../../../../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LayoutHomeService {
    private companyLogo = '';
    private logoLoginImage = '';
    private companyBgImg = '';
    private companyPasswordRecoverUrl = '';

    get logo(): string {
        return this.companyLogo;
    }

    set logo(logo: string) {
        this.companyLogo = logo;
    }

    get bgImg(): string {
        return this.companyBgImg;
    }

    set bgImg(bgImg: string) {
        this.companyBgImg = bgImg;
    }

    get logoLogin(): string {
        return this.logoLoginImage;
    }

    set logoLogin(logoLogin: string) {
        this.logoLoginImage = logoLogin;
    }

    get passwordRecoverUrl(): string {
        return this.companyPasswordRecoverUrl;
    }

    set passwordRecoverUrl(passwordRecoverUrl: string) {
        this.companyPasswordRecoverUrl = passwordRecoverUrl;
    }

    get projectName(): string {
        const name = "";

        if (!name?.trim()) {
            return Translate.value("root.projectName");
        }

        return name;
    }

    get isAtDevEnvironment() {
        return location.origin.includes("http://localhost:4200");
    }

    // getImageUrl(imageUrl: string): string
    // {
    //     // return `${environment.accountApi}/${imageUrl}`;
    //     return ``;
    // }

    constructor() {
    }
}
