import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';


@Injectable({
    providedIn: 'root'
})
export class MenuToolbarService{
	isOpenSubject = new Subject<boolean>();

    constructor(){}


	closeToolbar(){
		this.isOpenSubject.next(false);
	}

	openToolbar(){
		this.isOpenSubject.next(true);
	}
}
