import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    ViewChild
} from '@angular/core';
import { Params, QueryParamsHandling } from "@angular/router";
import { ButtonColor } from "../../../../types/button-color";
import { Generic } from "../../../../models/generic";
import { FormBadge } from "../../../../models/form-badge";
import { ButtonIconColor } from "../../../../types/button-icon-color";
import { Auxiliary } from "../../../../helpers/auxiliary";
import { FormButtonService } from "./form-button.service";
import { ButtonThemes } from "../../../../models/form-button";



@Component({
    selector: 'app-form-button',
    templateUrl: './form-button.component.html',
    styleUrls: ['./form-button.component.scss']
})
export class FormButtonComponent implements AfterViewInit{
    @ViewChild('button', { read: ElementRef }) button: ElementRef;
    @Output() htmlElement = new EventEmitter<HTMLElement>();
    @Input() context: any;
    @Input() icon = '';
    @Input() tooltip = '';
    @Input() color: ButtonColor = 'primary';
    @Input() theme: ButtonThemes = 'basic';
    @Input() href = '';
    @Input() svgIcon = '';
    @Input() target = '_self';
    @Input() badge: FormBadge;
    @Input() loading = false;
    @Input() queryParamsHandling: QueryParamsHandling|null = null;
    @Input() queryParams: Params|null = null;
    @Input() styleConfig: Generic = {};
    @Input() tabindexBtn = 0;
    classes = 'button';
    condition = true;
    type = 'button';
    text = ''

    @Input('click')
    set setClick(click: any){
        if(click) this.click = click;
    }

    @Input('disabled')
    set setDisabled(disabled: any){
        if(disabled) this.disabled = disabled;
    }

    @Input('classes')
    set setClasses(classes: string){
        if(classes) this.classes = `${this.classes} ${classes}`;
    }

    @Input('iconColor')
    set setIconColor(color: ButtonIconColor){
        if(color) this.classes = `${this.classes} button--${color}`;
    }

    @Input('text')
    set setText(text: string | ((...properties: any[]) => string)){
        if(typeof text === 'string') this.text = text
        else if(typeof text === 'function') this.text = text(this.context);
    };

    @Input('condition')
    set setCondition(condition: boolean){
        if(!Auxiliary.isNull(condition) && !Auxiliary.isUndefined(condition)) this.condition = condition;
    }

    @Input('type')
    set setType(type: string){
        if(type) this.type = type;
    }

    constructor(private formButtonService: FormButtonService, public changeDetector: ChangeDetectorRef){}

    disabled: any = () => false;
    click: any = () => {};

    ngAfterViewInit(): void{
        this.htmlElement.next(this.button?.nativeElement);
    }

    onClick(evt: MouseEvent|KeyboardEvent): void{
        this.formButtonService.setLastClickedButton(this);

        this.click(evt);

        setTimeout(() => this.formButtonService.setLastClickedButton(null), 100);
    }
}
