<mat-form-field [appearance]="appearance" class="select" [formGroup]="form" [floatLabel]="floatLabel">
    <mat-label>{{ label | translate }}</mat-label>
    <mat-select [placeholder]="placeholder | translate"
                #select
                (focus)="onFocus()"
                [required]="required"
                [formControlName]="name"
                [(value)]="value"
                [sortComparator]="sort"
                [multiple]="multiple">
        <mat-select-trigger><span>{{ displayValue() | translate }}</span></mat-select-trigger>
        <mat-option *ngIf="isLoading" disabled="true">
            <mat-spinner mode="indeterminate"
                         class="select__loading"
                         [strokeWidth]="3"
                         [diameter]="30">
            </mat-spinner>
        </mat-option>
        <mat-option *ngIf="nothing && !isLoading && !multiple" [value]="nothingValue">{{ nothing | translate }}</mat-option>
        <mat-option *ngFor="let option of options" [value]="option[sendProperty]">
            {{ option[nameProperty] | translate }}
        </mat-option>
    </mat-select>
    <mat-icon aria-hidden="true" matSuffix *ngIf="icon">{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
