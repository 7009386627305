import { Injectable } from "@angular/core";
import { ConvertHelpers } from "../helpers/convert-helpers";
import { ValidUrlValue } from "../types/valid-url.value";
import { DateHelper } from "../helpers/date-helper";

@Injectable({
	providedIn: "root",
})
export class UrlRouterParserService {
	parseParams(params: Record<string, string>) {
		const parsed: Record<string, ValidUrlValue> = {};

		Object.entries(params).forEach(([key, value]) => {
			const convertedKey = ConvertHelpers.kebabCaseToCamelCase(key);
			parsed[convertedKey] = this.parse(value);
		});

		return parsed;
	}

	stringifyParams(params: Record<string, ValidUrlValue>) {
		const stringified: Record<string, string> = {};

		Object.entries(params).forEach(([key, value]) => {
			const convertedKey = ConvertHelpers.camelCaseToKebabCase(key);
			const result = this.stringify(value);
			if (!result) return;

			stringified[convertedKey] = result;
		});

		return stringified;
	}

	private parse(value: string): ValidUrlValue {
		const isNumber = !isNaN(+value);
		const isArray = value.includes("(") && value.includes(")");
		const isBoolean = value === "true" || value === "false";
		const isDate = DateHelper.isDate(value);

		if (isNumber) return +value;
		if (isBoolean) return value === "true";
		if (isDate) return new Date(value);

		if (isArray) {
			value = value.replace("(", "");
			value = value.replace(")", "");

			return value.split(",").map(item => this.parse(item));
		}


		return value;
	}

	private stringify(value: ValidUrlValue) {
		if (value instanceof Date)
			return value.toISOString();

		if (Array.isArray(value)) {
			if (!value.length) return;
			return `(${value.toString()})`;
		}

		return value?.toString?.() ?? "";
	}
}
