import {createReducer, on} from '@ngrx/store';
import {RouteAreaType} from 'src/app/shared/types/route-area-type';
import {changeAreaAction} from '../actions/routes';

export interface State {
    routeArea: RouteAreaType,
    currentUrl: string
}

export const initialState: State = {
    routeArea: 'UNKNOWN_AREA',
    currentUrl: ''
}

export const reducer = createReducer(initialState,
    on(changeAreaAction, (state, {payload: {type, url}}) => {
        return {...state, routeArea: type, currentUrl: url}
    }),
)
