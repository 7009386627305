import { Injectable } from "@angular/core";
import { Generic } from "../../shared/models/generic";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { take } from "rxjs/operators";

@Injectable({
	providedIn: "root",
})
export class ForceChangePasswordService {
	constructor(private http: HttpClient) {
	}
	changePassword(value: Generic){
		const data = {
			currentPassword: value.currentPassword,
			user: {
				password: value.password,
				passwordConfirmation: value.passwordConfirmation,
			}
		}
		return this.http.patch(`${environment.api}/current_user/change_password`, data).pipe(take(1));
	}
}
