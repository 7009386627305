import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-no-results',
    templateUrl: './no-results.component.html',
    styleUrls: ['./no-results.component.scss'],
    preserveWhitespaces: false
})
export class NoResultsComponent {
    @Input() label = '';
    @Input() icon = '';
    @Input() description = '';
}
