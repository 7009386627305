import { Component, Input } from '@angular/core';
import { QueryParamsHandling } from "@angular/router";
import { environment } from "../../../../../environments/environment";


@Component({
    selector: 'app-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent{
    @Input() tooltip = '';
    @Input() text = '';
    @Input() link = '';
    @Input() target = '_self';
    @Input() queryParamsHandling: QueryParamsHandling = '' as QueryParamsHandling;
    @Input() transparentBackgroundColor = false;
    @Input() inheritCursor = false;
    icon = 'account_circle';
    imageUrl = '';
    classes = 'avatar__link';
    environment = environment;

    @Input('icon')
    set setIcon(icon: string){
        if(icon) this.icon = icon;
    }

    @Input('imageUrl')
    set setImageUrl(url: string){
        if(url) this.imageUrl = `${this.environment?.api}${url}`;
        else this.imageUrl = '';
    }

    @Input('classes')
    set setClasses(classes: string){
        if(classes) this.classes = `${this.classes} ${classes}`;
    }

    constructor(){}
}
