export const COMMITTEES_TRANSLATE = {
    committees: {
        messages: {
            create: "Comitê adicionado com sucesso",
            update: "Comitê atualizado com sucesso",
            delete: "Comitê deletado com sucesso",
            recover: "Comitê recuperado com sucesso"
        },
        list: {
            table: {
                columns: {
                    id: "ID",
                    name: "Nome"
                }
            },
            filters: {
                fields: {
                    id: {
                        label: "ID",
                        placeholder: "Informe o ID",
                        url: "id"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome",
                        url: "nome"
                    },
                    active: {
                        label: "Situação",
                        placeholder: "Selecione a situação",
                        url: "situacao"
                    }
                }
            },
            dialog: {
                delete: {
                    title: "Inativar comitê",
                    body: "Tem certeza que deseja inativar o comitê com o ID {{id}}",
                    save: {
                        text: "Sim, inativar"
                    },
                    back: {
                        text: "Voltar"
                    },

                }
            }
        },
        save: {
            form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"

                    },
                    userIds: {
                        label: "Usuários",
                        placeholder: "Selecione os usuários"

                    },
                    categoryIds: {
                        label: "Categorias",
                        placeholder: "Selecione as categorias"

                    },
                }
            }
        }
    }
}