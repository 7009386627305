import {environment} from "src/environments/environment";
import { BaseImage } from "../models/base-image";
import { BaseAttachment } from "../models/base-attachment";
import { FileHelper } from "./file-helper";

export class ConvertHelpers {
	static arrayToString(array: any[] | null, separator: string = ",") {
		if (!array) return "";
		return array.join(separator);
	}

	static urlToHttp(url: string) {
		if (!url.startsWith('http')) url = `http://${url}`;
		if (url.startsWith('https')) url = url.replace('https', 'http');
		return url;
	}

	static urlToHttps(url: string) {
		if (!url.startsWith('http')) url = `https://${url}`;
		if (!url.startsWith('https')) url = url.replace('http', 'https');
		return url;
	}

	static removeLastSlash(text: string) {
		return text.replace(/\/$/, '');
	}

	static removeFirstSlash(text: string) {
		return text.replace(/^\//, '');
	}

	static kebabCaseToCamelCase(text: string) {
		const capitalize = (word: string) => word[0].toUpperCase() + word.slice(1, word.length);

		const words = text.split('-').map((word, index) => {
			const isFirstWorld = index === 0;

			return isFirstWorld ? word : capitalize(word);
		});

		return words.join("");
	}

	static camelCaseToKebabCase(text: string) {
		return text.split('').map((letter, idx) => letter.toUpperCase() === letter
			? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}`
			: letter).join('');
	}

	static fileInfoFromBaseImage(baseImage: BaseImage, quality: 'large' | 'medium' | 'small' | 'original' = 'original') {
		const urls = {
			large: baseImage.larger,
			medium: baseImage.medium,
			small: baseImage.small,
			original: baseImage.url,
		};

		return FileHelper.getFileInfo({
			identifier: baseImage.id,
			mimeType: baseImage.contentType ?? 'image/png',
			src: environment.api + urls[quality]
		});
	}

	static fileInfoFromBaseAttachment(baseAttachment: BaseAttachment) {
		return FileHelper.getFileInfo({
			identifier: baseAttachment.id,
			mimeType: baseAttachment.contentType,
			name: baseAttachment.filename,
			src: environment.api + baseAttachment.url
		});
	}

	static fileToFileInfo(file: File) {
		return FileHelper.getFileInfo({
			mimeType: file.type,
			src: FileHelper.getFileSrc(file),
			name: file.name,
			size: file.size,
			source: file
		});
	}
}
