import { Router } from '@angular/router';

import { FormButton } from "../models/form-button";
import { Auxiliary } from "../helpers/auxiliary";
import { Translate } from "../helpers/translate";
import { FiltersService } from "../../core/filters/filters.service";
import { ReportGenerator } from "../models/report-generator";
import { FiltersV2Service } from "../../core/filters/filters-v2.service";



export const SAVE_BUTTON = (custom?: Partial<FormButton>) => {
	const defaultButton: FormButton = {
        text: 'generic.actions.save',
        theme: 'raised',
        color: 'primary',
        tooltip: 'generic.actions.save',
        condition: () => true,
    };

    return {
        ...defaultButton,
        ...custom
    };
};

const FAB_BUTTON: FormButton = {
	text: '',
	icon: 'add',
	theme: 'mini-fab',
	color: 'accent',
	condition: () => true,
};

export const CREATE_BUTTON = (router?: Router | null, custom?: Partial<FormButton>) => {
	let defaultButton: FormButton = {
		...FAB_BUTTON,
		text: 'generic.actions.add',
		tooltip: 'generic.actions.add',
		icon: 'add',
	};

	if(router){
		const currentUrl = Auxiliary.removeUrlParameters(router.url);
		const urlForNew = Translate.value('routes.genericRoutes.new');

		defaultButton = {
			...defaultButton,
			href: `${currentUrl}/${urlForNew}`
		};
	}

	return {
		...defaultButton,
		...custom
	};
};

export const BACK_BUTTON = (click: FormButton["click"], custom?: Partial<FormButton>) => {
	const defaultButton: FormButton = {
		theme: "stroked",
		color: "accent",
		text: 'generic.actions.back',
		tooltip: 'generic.actions.back',
		click: () => click?.(),
		condition: () => true
	};

	return {
		...defaultButton,
		...custom
	};
};

export const TAB_CREATE_BUTTON = (click: () => void, custom?: Partial<FormButton>) => {
	let defaultButton: FormButton = {
		...FAB_BUTTON,
		text: 'generic.actions.add',
		tooltip: 'generic.actions.add',
		icon: 'add',
	};

	defaultButton = {
		...defaultButton,
		click
	};

	return {
		...defaultButton,
		...custom
	};
};

export const IMPORT_BUTTON = (click: () => void, custom?: FormButton) => {
	let defaultButton: FormButton = {
		...FAB_BUTTON,
		icon: "file_upload",
		text: "generic.actions.import",
		tooltip: "generic.actions.import",
		color: "primary"
	};

	defaultButton = {
		...defaultButton,
		click
	};

	return{
		...defaultButton,
		...custom
	};
};

export const FILTER_BUTTON = (service: FiltersService | FiltersV2Service, custom?: FormButton) => {
    const DEFAULT_BUTTON: FormButton = {
        text: 'localActions.filters.text',
        icon: "filter_list",
        tooltip: 'localActions.filters.tooltip',
        color: 'primary',
        theme: 'icon',
        badge: { value: service.watchActives(), description: 'localActions.filters.badge' },
        condition: () => true,
        click: () => service.open()
    };

    return{
        ...DEFAULT_BUTTON,
        ...custom
    };
};

export const GENERATE_REPORT_BUTTON = (generator: ReportGenerator, custom?: Partial<FormButton>) => {
    const DEFAULT_BUTTON: FormButton = {
        text: 'localActions.generateReport.text',
        icon: 'description',
        tooltip: 'localActions.generateReport.tooltip',
        color: 'primary',
        theme: 'mini-fab',
        condition: () => true,
        click: () => generator.generateReport()
    };

    return{
        ...DEFAULT_BUTTON,
        ...custom
    };
};
