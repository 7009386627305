import { FormControl, FormGroup } from "@angular/forms";
import { distinctUntilChanged, filter, map, startWith, take, tap } from "rxjs/operators";
import { combineLatest, Observable } from "rxjs";
import { FormChangeDetector } from "./interfaces/form-change-detector";
import { FromControlOptions } from "./interfaces/from-control-options";
import { Generic } from "../models/generic";
import { Auxiliary } from "../helpers/auxiliary";


export type ObjectFromArrayString<T extends readonly string[]> = {
	[k in T[number]]: any;
};

const fromControlValue = <T>(arg1: unknown, arg2?: unknown, arg3?: unknown): Observable<T> =>  {
	let control: FormControl | undefined;
	let options: FromControlOptions<T> | undefined;

	if (typeof arg1 === 'string' && arg2 instanceof FormGroup) {
		control = arg2.get(arg1) as FormControl;
		if (!control) throw new Error(`Control name ${arg1} not found on this form`);

		options = arg3 as FromControlOptions<T> | undefined;
	} else {
		control = arg1 as FormControl;
		options = arg2 as FromControlOptions<T> | undefined;
	}

	if (!control) throw new Error('Control not provided');

	const startValue = options?.startValue;
	const detector = options?.detector ?? (() => false);

	const currentValue = typeof startValue === 'undefined' ? control.value : startValue;
	return control.valueChanges.pipe(
		distinctUntilChanged(detector),
		startWith(currentValue)
	);
}

function fromControlsValue<T extends readonly string[] = string[]>(
	controlNames: T,
	form: FormGroup,
	changeDetectors: FormChangeDetector[] = []
) {
	return combineLatest([...controlNames].map(name => {
		const detector = changeDetectors
			.find(detectorToFind => detectorToFind.controlName === name)?.detector;

		return fromControlValue(name, form, {
			detector
		}).pipe(
			map(control => ({name, value: control}))
		);
	})).pipe(map(controls => {
		const controlDictionary: Generic = {};

		controls.forEach(({name, value}) => {
			controlDictionary[name] = value;
		});


		return controlDictionary as ObjectFromArrayString<T>;
	}));
}

export const fromFormValue = <T>(
	form: FormGroup,
	changeDetectors: FormChangeDetector[] = []
) => {
	const keys = Object.keys(form.controls);

	return fromControlsValue(keys, form, changeDetectors);
};

export const filterFalsy = () => filter(value => !value);
export const filterTruthy = () => filter(<T>(value: T): value is Exclude<T, null | undefined>  => !!value);
export const filterNumber = () => filter((value: any): value is number => Auxiliary.isNumber(value));
export const filterString = () => filter((value: any): value is string => Auxiliary.isString(value));
export const filterNotEmptyArrays = () => filter(<T>(value: T[]) => !!value?.length);

export const tapOnce = <T>(fn: ((value: T) => void)) => function(source: Observable<T>) {
	source
		.pipe(
			take(1),
			tap(value => fn(value))
		)
		.subscribe();

	return source;
};

export const subscribeOnce = <T>(observable: Observable<T>) => {
	let value: T | undefined;

	observable.pipe(
		tapOnce(observableValue => value = observableValue)
	).subscribe();

	return value;
};

export const mapArray = <T, R>(fn: (item: T) => R) => function(source: Observable<T[]>){
	return source.pipe(map(((items) => {
		if(!Array.isArray(items))
			throw new Error("The observable is not an array");
		return items.map(fn);
	})));
};

export const filterArray = <T>(fn: (item: T) => boolean) => function(source: Observable<T[]>){
	return source.pipe(map(((items): T[] => {
		if(!Array.isArray(items))
			throw new Error("The observable is not an array");
		return items.filter(fn);
	})));
};

export const cast = <T>() => function(source: Observable<any>){
	return source.pipe(map(((items) => items as T)));
};

export const debug = <T>(tag = 'log') => tap<T>({
	next(value) {
		console.log(`%c[${tag}: Next]`, "background: #009688; color: #fff; padding: 3px; font-size: 9px;", value);
	},
	error(error) {
		console.log(`%[${tag}: Error]`, "background: #E91E63; color: #fff; padding: 3px; font-size: 9px;", error);
	},
	complete() {
		console.log(`%c[${tag}]: Complete`, "background: #00BCD4; color: #fff; padding: 3px; font-size: 9px;");
	}
});

export {
	fromControlValue,
	fromControlsValue
};
