export const COMPLAINTS_TRANSLATE = {
    complaints: {
        messages: {
            conclude: "Análise de denúncia concluída com sucesso",
            sendToAnalysis: "Iniciada análise da denúncia",
            enableMessageFromComplainant: "Habilitado envio de comentários pelo denunciante com sucesso",
            disableMessageFromComplainant: "Desabilitado envio de comentários pelo denunciante com sucesso",
            notFound: "Denúncia não encontrada",
            chooseCategory: "Denúncia classificada com sucesso"
        },
        params: {
            you: "Você",
            complainant: "Denunciante",
            committees: "Comitê de análise"
        },

        create: {
            confirmDialog: {
                title: "Atenção!",
                body: 'Deseja confirmar o envio desta denúncia?',
                back: {
                    text: "Fechar",
                },
                save: {
                    text: "Sim, confirmar"
                }
            },
            helpDialog: {
                title: "Precisa de ajuda?"
            }
        },

        afterCreate: {
            title: "Sua denúncia foi enviada",
            imageAlt: "Logo do canal de ética",
            message: "Esse é o seu número de protocolo. Anote e guarde em local seguro, pois ele é a única forma para você acompanhar sua denúncia."
        },

        noResults: {
            label: "Ainda não há nenhum Comentário nesta denúncia"
        },
        list: {
            table: {
                columns: {
                    protocolNumber: "Protocolo",
                    category: "Categoria",
                    createdAt: "Criado em",
                    status: "Situação"

                }
            },
            filters: {
                fields: {
                    protocolNumber: {
                        label: "Nº do protocolo",
                        placeholder: "Informe o número do protocolo",
                        url: "numero-protocolo"
                    },
                    category: {
                        label: "Categoria",
                        placeholder: "Selecione a categoria",
                        url: "categoria"
                    },
					complaintType: {
                        label: "Canal",
                        placeholder: "Selecione o canal",
                        url: "canal"
                    },
                    status: {
                        label: "Situação",
                        placeholder: "Selecione a situação",
                        url: "situacao"
                    },
                }

            }
        },


        confirmAction: {
            title: "Atenção!",
            message: 'Deseja confirmar o envio do comentário "{{message}}"? Este comentário ficará visível para o denunciante e não poderá ser alterado.',
            alternativeMessage: "Deseja confirmar o envio do comentário \"{{message}}\"? Este comentário NÃO ficará visível para o denunciante e NÃO poderá ser alterado.",
            complainantMessage: "Deseja confirmar o envio do comnetário \"{{message}}\"? Este comentário NÃO poderá ser excluído após o envio",
            back: {
                text: "Fechar",
            },
            save: {
                text: "Sim, confirmar"
            }
        },

        actions: {
            new: {
                text: "Faça uma denúncia"
            },
            womanChannel: {
                text: "Canal da mulher"
            },
            choiceHelpDialog: {
                text: "Não sabe qual escolher?"
            },
            followUpComplaints: {
                text: "Acompanhar minha denúncia"
            },
            enableComplainantToAnswer: {
                text: "Permitir comentários pelo denunciante"
            },
            disableComplainantToAnswer: {
                text: "Bloquear comentários pelo denunciante"
            },
            help: {
                text: "Ajuda"
            }

        },
        tabs: {
            general: "Geral",
            asks: "Informações adicionais"
        },
        save: {
            form: {
                actions: {
                    save: {
                        text: "Enviar denúncia"
                    }
                },
                fields: {
                    attachments: {
                        label: "Anexos",
                        noResults: {
                            label: "Você ainda não adicionou nenhum anexo",
                            description: "",
                            action: {
                                label: "Adicionar anexo"
                            }
                        }
                    },
                    categoryId: {
                        label: "Tipo de denúncia",
                        placeholder: "Selecione o tipo de denúncia"
                    },
                    description: {
                        label: "Digite sua denuncia",
                        placeholder: ""
                    },
                    text: {
                        label: "Comentário",
                        placeholder: ""
                    },
                    visibleForComplaint: {
                        label: "Visível para o denunciante",
                        tooltip: "Este comentário vai ficar vísivel para o denunciante, no momento da consulta da denúncia"
                    }
                }
            }
        },

        single: {
            confirmActions: {
                sendToAnalysis: {
                    title: "Atenção!",
                    body: 'Deseja iniciar o atendimento da denúncia com o protoloco {{protocol}}?',
                    back: {
                        text: "Fechar",
                    },
                    save: {
                        text: "Sim, confirmar"
                    }
                },

                conclude: {
                    title: "Atenção!",
                    body: 'Deseja concluir o atendimento da denúncia com o protoloco {{protocol}}?',
                    back: {
                        text: "Fechar",
                    },
                    save: {
                        text: "Sim, confirmar"
                    }
                },
                allowComplainant: {
                    title: "Atenção!",
                    body: 'Deseja permitir o envio de comentários pelo denunciante?',
                    back: {
                        text: "Fechar",
                    },
                    save: {
                        text: "Sim, confirmar"
                    }
                },
                disableComplainant: {
                    title: "Atenção!",
                    body: 'Deseja desabilitar o envio de comentários pelo denunciante?',
                    back: {
                        text: "Fechar",
                    },
                    save: {
                        text: "Sim, confirmar"
                    }
                },

                chooseCategory: {
                    title: "Atenção!",
                }

            },
            fields: {
                description: "Descrição",
                protocolNumber: "Número do protocolo",
                createdAt: "Criado em",
                category: "Categoria",
                status: "Situação da denúncia",
                attachments: "Anexos"

            },
            actions: {
                conclude: {
                    text: "Concluir",
                },
                start: {
                    text: "Iniciar"
                },
                chooseCategory: {
                    text: "Classificar"
                }
            }
        },
        followUp: {
            title: "Digite o número de protocolo para acompanhar sua denúncia",
            form: {
                fields: {
                    protocol: {
                        label: "Número do protocolo",
                        placeholder: "Informe aqui o número do protocolo"
                    }
                }
            }

        },
        investigationSteps: {
            noResults: {
                label: "Ainda não há nenhum comentário nesta denúncia"
            },
            params: {
                visibleForComplainant: "Visível para o denunciante"
            }
        },
        choiceHelpDialog: {
            title: "Qual devo escolher?"
        },
        asks: {
            params: {
                answer: "R:"
            }
        }

    }
}
