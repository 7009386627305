import { HttpErrorResponse, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from "@angular/common/http";
import { Params } from "@angular/router";
import { SlicePipe } from "@angular/common";

import { Observable, of, Subscription } from "rxjs";
import { Color } from "@angular-material-components/color-picker";

import { Generic } from "../models/generic";
import { EqualRoute } from "../models/equal-route";
import { Translate } from "./translate";
import { Photo } from "../models/photo";

export class Auxiliary {
    static slicePipe = new SlicePipe();
    static yes = '';
    static no = '';
    static lastSeparator = '';

    static setAuxiliaryWords(): void {
        setTimeout(() => {
            Auxiliary.yes = Translate.value('booleans.yes');
            Auxiliary.no = Translate.value('booleans.no');
            Auxiliary.lastSeparator = Translate.value('auxiliary.lastSeparator');
        }, 3000);
    }

    static flagCol(val: boolean): string {
        return val ? Auxiliary.yes : Auxiliary.no;
    }

    static dateColumn(date = 'dd/MM/yyyy', hour = 'HH:mm'): string {
        if (date && hour) return `${date} '${Translate.value('date.dateAndHourSeparator')}' ${hour}`;
        else if (date) return date;
        else return hour;
    }

    static ellipsis(text = '', limit = Infinity): string {
        const word = text.trim();

        return limit === Infinity || word.length <= limit ? word : `${Auxiliary.slicePipe.transform(word, 0, limit)}…`;
    }

    static isEmptyString(prop: any): boolean {
        return prop === '';
    }

    static isEmptyObject(prop: {} | Generic): boolean {
        return Object.keys(prop).length === 0;
    }

    static isDate(prop: any): prop is Date {
        return prop instanceof Date;
    }

    static toNumber(prop: any): prop is number {
        return prop ? Number(prop) : prop;
    }

    static isArray(prop: any): boolean {
        return Array.isArray(prop);
    }

    static isFunction(prop: any): boolean {
        return typeof (prop) === 'function';
    }

    static isUndefined(prop: any): boolean {
        return prop === undefined;
    }

    static isNull(prop: any): boolean {
        return prop === null;
    }

    static getEnumLength(enumObject: Generic): number {
        return Object.keys(enumObject).length / 2;
    }

    static isBlob(prop: any): boolean {
        return prop instanceof Blob;
    }

    static isString(prop: any): boolean {
        return typeof prop === 'string' || prop instanceof String;
    }

    static asKeyof(key: string = '', object: Generic = {}): any {
        return key as keyof typeof object;
    }

    static isInternalServerError(response: HttpErrorResponse): boolean {
        return response?.status === 500;
    }

    static is404(response: HttpErrorResponse): boolean {
        return response?.status === 404;
    }

    static isHTML(prop: string): boolean {
        return Auxiliary.isString(prop) ? /<(?:"[^"]*"['"]*|'[^']*'['"]*|[^'">])+>/.test(prop) : false;
    }

    static noAccess(status: number): boolean {
        return status === 403;
    }

    static isApiNotFound(response: HttpErrorResponse): boolean {
        return response?.status === 0 && response?.statusText === "Unknown Error";
    }

    static decodeToParse(expression: string | null): any {
        return expression ? JSON.parse(decodeURI(expression)) : null;
    }

    static removeDoubleQuotationMarks(text: string): string {
        return text.replace(/[\\"]/g, '');
    }

    static removeAllSpaces(text: string): string {
        return text.replace(/\s/g, '');
    }

    static isMailto(href = ''): boolean {
        return href.includes('mailto:');
    }

    static copy(expression: any): any {
        return Auxiliary.isObject(expression) ? {...expression} : JSON.parse(JSON.stringify(expression));
    }

    static removeInvalidValuesFromArray(list: any[] = []): any[] {
        return list.filter(value => !Auxiliary.isUndefined(value) && !Auxiliary.isNull(value));
    }

    static isAlphabetLetter(letter: string): boolean {
        const code = letter?.charCodeAt(0);

        return (code >= 65 && code <= 90) || (code >= 97 && code <= 122);
    }

    static createObservable(property?: any): Observable<any> {
        return property instanceof Observable ? property : of(property);
    }

    static isArrayOfObjects(property: any): boolean {
        return Auxiliary.isArray(property) ? property.some((item: Generic) => Auxiliary.isObject(item)) : false;
    }

    static capitalizeFirstLetter(text = ""): string {
        return `${text.charAt(0).toUpperCase()}${text.slice(1).toLowerCase()}`;
    }

    static redirectToLogin(status: number) {
        return status === 401;
    }

    static hexToRgb(hex: string): number[] {
        return hex.replace(
            /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
            (m: string, r: string, g: string, b: string) => '#' + r + r + g + g + b + b
        )
            ?.substring(1)
            ?.match(/.{2}/g)
            ?.map(x => parseInt(x, 16)) as number[];
    }

    static isFormData(property: any): any {
        return property instanceof FormData;
    }

    static isInstanceOfFile(property: any): boolean {
        return property instanceof File;
    }

    static isModuleLoadingError(error: any): boolean {
        return error?.message?.includes('ChunkLoadError');
    }

    static isPermissionError(error: Error): boolean {
        const message = error?.message || '';

        return message.includes('NotAllowedError') || message.includes('Permission');
    }

    static typeColorToHex(color: Color): string {
        return `#${color.hex}`;
    }

    static removeRepeatedItems(list: any[]): any[] {
        return list.filter((item, index) => list.indexOf(item) === index);
    }

    static objectHasKeysLength(object: Generic): boolean {
        return !!Object.keys(object || {}).length;
    }

    static removeUrlParameters(url: string): string {
        return url?.replace(/\?.+/g, '');
    }

    static setRequestUrl(url: string, defaultType: string): string {
        const urlSplitted = url.split('/');

        return urlSplitted[urlSplitted.length - 1].includes('.') ? url : `${url}.${defaultType}`;
    }

    static getItemLocalStorage(key: string): any {
        return JSON.parse(localStorage.getItem(key) as string);
    }

    static setItemLocalStorage(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static transformInFileName(fileName = ''): string {
        return decodeURIComponent(fileName).replace(/[`~!@#$%^&*()|+\=?;:'",<>\{\}\[\]\\\/]/gi, '');
    }

    static objToQueryParams(obj: Params, prefix = ''): string {
        return `${prefix}${Object.entries(obj).map(keyAndVal => keyAndVal.join('=').trim()).join('&').trim()}`.trim();
    }

    static unitToNumber(unit: string): number {
        return Number(unit?.replace(/\D/g, '') || 0) || 0;
    }

    static quantityBadgeValue(value: number, limit = 9): string | undefined {
        const isBiggerThanLimit = value > limit;

        return !value ? undefined : isBiggerThanLimit ? `+${limit}` : value.toString();
    }

    static correctInputData(value: string | null | undefined): string {
        if (!value) return "";

        const valueTrimed = value.trim();

        if (!valueTrimed) return valueTrimed;

        return value;
    }

    static getFileType(data: Blob | File): string {
        return data.type.split('/')[1];
    }

    static getFileName(file: Photo): string {
        const splitted = (file.small || file.medium || file.larger || file.url).split('/');

        return Auxiliary.transformInFileName(splitted[splitted.length - 1]);
    }

    static async base64ToBlob(base64: string): Promise<Blob> {
        const response = await fetch(base64);
        const blob = await response.blob();

        return blob;
    }

    static async forceLoadMedia(
        element: HTMLAudioElement | HTMLVideoElement,
        options: { msTimeout: number; skipTime: number }
    ): Promise<void> {
        while (element.duration === Infinity) {
            await new Promise(resolve => setTimeout(resolve, options.msTimeout));

            element.currentTime = options.skipTime * Math.random();
        }

        element.currentTime = 0;
    }

    static computedPropertyValue(computed: CSSStyleDeclaration, property: string): number {
        return parseInt(computed.getPropertyValue(property), 10);
    }

    static autoExpandFormTextarea(element: HTMLElement, time = 0): void {
        setTimeout(() => {
            element.style.height = 'inherit';

            const computed = window.getComputedStyle(element);
            const maxHeight = Auxiliary.computedPropertyValue(computed, 'max-height');
            const padding = Auxiliary.computedPropertyValue(computed, 'padding-top')
                + Auxiliary.computedPropertyValue(computed, 'padding-bottom');
            let initialHeight = element.dataset['initialHeight'];

            if (!initialHeight) initialHeight = `${element.offsetHeight - padding}`;

            const scrollHeight = element.scrollHeight - padding;
            const newInitialHeight = parseFloat(initialHeight);
            const height = scrollHeight <= newInitialHeight ? newInitialHeight : scrollHeight;

            element.style.overflowY = maxHeight && height > maxHeight ? 'auto' : 'hidden';
            element.style.height = `${height}px`;
        }, time);
    }

    static isNumber(prop: any): boolean {
        return typeof (Number(prop)) === 'number' &&
            !isNaN(Number(prop)) &&
            !Auxiliary.isUndefined(prop) &&
            !Auxiliary.isNull(prop) &&
            !Auxiliary.isEmptyString(prop) &&
            !Auxiliary.isArray(prop) &&
            !Auxiliary.isBoolean(prop);
    }

    static offSetElement(element: HTMLElement): { top: number; left: number } {
        let top = 0;
        let left = 0;

        if (element)
            while (element && !isNaN(element.offsetLeft) && !isNaN(element.offsetTop)) {
                top += element.offsetLeft - element.scrollLeft;
                left += element.offsetTop - element.scrollTop;
                element = element.offsetParent as HTMLElement;
            }

        return {top, left};
    }

    static listToString(
        list: any[] = [],
        nameProperty?: string,
        separator?: string,
        lastSeparator?: string,
        translate?: boolean,
    ): string {
        return list.map(item => {
            const name = nameProperty ? item[nameProperty] : item;

            return translate ? Translate.value(name) : name;
        })
            .join(separator || ', ')
            .replace(/,(?=[^,]*$)/, lastSeparator || Auxiliary.lastSeparator);
    }

    static camelCaseToPascalCase(property: string): string {
        return `${property.charAt(0).toUpperCase()}${property.slice(1)}`;
    }

    static camelCaseToSnakeCase(property: string): string {
        let key = property.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);

        if (key.indexOf('_') === 0) key = key.replace('_', '');

        return key;
    }

    static snakeCaseToCamelCase(property: string) {
        return property.replace(/([-_][a-z])/ig, letter =>
            letter.toUpperCase().replace('-', '').replace('_', '')
        );
    }

    static transformListIntoConcatenatedSnakeCaseProperties(list: string[] | string = [], separator: string = ','): string {
        if (typeof (list) === 'string') list = list.split(separator);

        return list.map(property => Auxiliary.camelCaseToSnakeCase(property))
            .join(separator) || '';
    }

    static isObject(prop: any, validateStringify?: boolean): boolean {
        const evaluationOfObject = (value: any): boolean =>
            (typeof value === 'object' || typeof value === 'function') && (value !== null);

        return evaluationOfObject(validateStringify ? Auxiliary.objectParser(prop) : prop);
    }

    static isBoolean(prop: any): boolean {
        try {
            return typeof (eval(prop)) === 'boolean';
        } catch (error) {
            return false;
        }
    }

    static removeInvalidProperties(object: Generic = {}): Generic {
        for (const propKey in object)
            if (object.hasOwnProperty(propKey) && (Auxiliary.isNull(object[propKey]) || Auxiliary.isUndefined(object[propKey])))
                delete object[propKey];

        return object;
    }

    static objectParser(property: any): any {
        let newProperty: any;

        try {
            newProperty = JSON.parse(property);
        } catch (event) {
            newProperty = property;
        }

        return newProperty;
    }

    static equalLetters(text: any = ''): string {
        return typeof (text) === 'string' ? text?.toLowerCase()?.trim() : '';
    }

    static onlyValidParameters(object: Generic = {}): Generic {
        const newObject = {} as Generic;

        for (const key in object) {
            if (object.hasOwnProperty(key)) {
                const value = object[Auxiliary.asKeyof(key, object)];

                if (!Auxiliary.isNull(value) && !Auxiliary.isUndefined(value) && !Auxiliary.isEmptyString(value))
                    newObject[Auxiliary.asKeyof(key, newObject)] = value;
            }
        }

        return newObject;
    }

    static createHttpParams(parameters: Params): HttpParams {
        let httpParams = new HttpParams();

        for (const propertyName in parameters) {
            if (parameters.hasOwnProperty(propertyName)) {
                const propertyValue = Auxiliary.removeDoubleQuotationMarks(
                    JSON.stringify(parameters[Auxiliary.asKeyof(propertyName, parameters)])
                );

                httpParams = httpParams.set(propertyName, propertyValue);
            }
        }

        return httpParams;
    }

    static setCorrectType(expression: any): any {
        if (Auxiliary.isNumber(expression) && !`${expression}`.startsWith("0")) return Number(expression);
        if (Auxiliary.isBoolean(expression)) return eval(expression);
        if (Auxiliary.isObject(expression, true)) return Auxiliary.objectParser(expression);
        if (Auxiliary.isString(expression)) return Auxiliary.removeDoubleQuotationMarks(expression);

        return expression;
    }

    static assignAllObjects(target: Generic, ...sources: any[]): Generic {
        sources.forEach(source => {
            if (source)
                Object.keys(source).forEach(key => {
                    const sourceValue = source[key];
                    const targetValue = target[key];

                    try {
                        target[key] =
                            targetValue
                            &&
                            Auxiliary.isObject(targetValue)
                            &&
                            Auxiliary.isObject(sourceValue)
                            &&
                            !Auxiliary.isFunction(targetValue)
                                ?
                                Auxiliary.assignAllObjects(targetValue, sourceValue)
                                :
                                sourceValue;
                    } catch (e) {
                        target[key] = sourceValue;
                    }
                });
        });

        return target;
    }

    static isEqualRoute(urls: string[], options: EqualRoute = {}): boolean {
        return urls.map(url => options.digitToSlice ? url.substring(0, url.indexOf(options.digitToSlice)) : url)
            .every(url => url === urls[0]);
    }

    static transformParamsInSnakeCase(httpParams: HttpParams): HttpParams {
        let newHttpParams = new HttpParams();

        httpParams.keys().forEach((key) => {
            let value = httpParams.get(key);

            if (value) {
                if (key === 'attributes') value = value.replace(/\[|\]/g, '');

                newHttpParams = newHttpParams.append(Auxiliary.camelCaseToSnakeCase(key), value);
            }
        });

        return newHttpParams;
    }

    static propertyInCamelCase(property: any): any {
        if (Auxiliary.isArray(property)) return property.map((key: any) => Auxiliary.propertyInCamelCase(key));
        else if (Auxiliary.isObject(property)) {
            const newObject: Generic = {};

            Object.keys(property).forEach(key =>
                newObject[Auxiliary.snakeCaseToCamelCase(key)] = Auxiliary.propertyInCamelCase(property[key]));

            return newObject;
        }

        return property;
    }

    static transformBodyInSnakeCase(property: any): any {
        if (Auxiliary.isFormData(property)) {
            const newFormData = new FormData();

            for (const [name, value] of [...property]) {
                if (Auxiliary.isInstanceOfFile(value))
                    newFormData.append(
                        Auxiliary.camelCaseToSnakeCase(name),
                        value,
                        Auxiliary.transformInFileName(value.name)
                    );
                else newFormData.append(Auxiliary.camelCaseToSnakeCase(name), Auxiliary.transformBodyInSnakeCase(value));
            }

            return newFormData;
        } else if (Auxiliary.isDate(property)) {
            return property.toJSON();
        } else if (Auxiliary.isArray(property)) {
            return property.map((key: any) => Auxiliary.transformBodyInSnakeCase(key));
        } else if (Auxiliary.isObject(property)) {
            const newObject: Generic = {};

            Object.keys(property).forEach(key =>
                newObject[Auxiliary.camelCaseToSnakeCase(key)] = Auxiliary.transformBodyInSnakeCase(property[key])
            );

            return newObject;
        }

        return property;
    }

    static mergeHeaders(...httpHeaders: HttpHeaders[]): HttpHeaders {
        let newHttpHeaders = new HttpHeaders();

        httpHeaders.forEach(httpHeader => {
            for (const key of httpHeader.keys())
                newHttpHeaders = newHttpHeaders.append(key, httpHeader.get(key) || '');
        });

        return newHttpHeaders;
    }

    static createFormDataByObjects(object: Generic = {}, principalObjectName?: string): FormData {
        const formData: FormData = new FormData();

        const appendToFormData = (appendObject: any = {}, objectName: string = '') => {
            if (Auxiliary.isInstanceOfFile(appendObject))
                formData.append(objectName, appendObject as File, Auxiliary.transformInFileName(appendObject.name));
            else if (Auxiliary.isDate(appendObject)) formData.append(objectName, appendObject.toJSON());
            else if (Auxiliary.isArray(appendObject))
                if (!appendObject.length) formData.append(objectName, 'null');
                else {
                    for (let index = 0; index < appendObject.length; index++) {
                        if (Auxiliary.isInstanceOfFile(appendObject[index])) {
                            appendToFormData(appendObject[index], `${objectName}[]`);
                        } else {
                            appendToFormData(appendObject[index], `${objectName}[${index}]`);
                        }

                    }
                }
            else if (typeof appendObject === 'object' && appendObject) {
                for (const key in appendObject)
                    if (appendObject.hasOwnProperty(key))
                        if (Auxiliary.isEmptyString(objectName)) appendToFormData(appendObject[key], key);
                        else appendToFormData(appendObject[key], `${objectName}[${key}]`);
            } else if (!Auxiliary.isNull(appendObject) && !Auxiliary.isUndefined(appendObject))
                formData.append(objectName, appendObject);
            else if (Auxiliary.isNull(appendObject) || Auxiliary.isUndefined(appendObject))
                formData.append(objectName, `${appendObject}`);
        };

        appendToFormData(object, principalObjectName);

        return formData;
    }

    static unsubscribeAll(subscriptions: Subscription[] = []): void {
        subscriptions.forEach(subscription => {
            if (subscription?.unsubscribe) subscription.unsubscribe();
        });
    }

    static convertHttpEvent(event: HttpEvent<any> | HttpErrorResponse): HttpEvent<any> | HttpErrorResponse {
        if (event instanceof HttpResponse || event instanceof HttpErrorResponse) {
            ['body', 'error'].forEach((objName: string) => {
                if (
                    event.hasOwnProperty(objName) &&
                    !Auxiliary.isInternalServerError(event as HttpErrorResponse) &&
                    !Auxiliary.isHTML((event as HttpErrorResponse)?.error)
                ) {
                    // @ts-ignore
                    const objNameCopy = {...event[objName]};

                    // @ts-ignore
                    if (Auxiliary.isObject(event[objName]) && !Auxiliary.isArray(event[objName]))
                        // @ts-ignore
                        for(const prop of Object.getOwnPropertyNames(event[objName])) try{ delete event?.[objName]?.[prop]; } catch(e){}

                    // @ts-ignore
                    try{ Object.assign(event[objName], Auxiliary.propertyInCamelCase(objNameCopy)); } catch(e){}
                }
            });
        }

        return event;
    }
    static stringFyAllParamsInArray(params: any[]) {
        return params.map(param => {
            const canDoStringfy = typeof param === "object" || param === null;
            if (canDoStringfy)
                return JSON.stringify(param);
            else if (param === undefined)
                return "undefined"
            else return param.toString();
        }).join(";")
    }


}

Auxiliary.setAuxiliaryWords();
