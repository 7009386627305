import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from "rxjs";

import { FiltersService } from './filters.service';
import { cast } from "../../shared/rxjs-operators";
import { Filter } from "./interfaces/filter";
import { FilterFieldInfo } from "./interfaces/filter-field-info";
import { RouterService } from "../../shared/services/router.service";
import { ValidUrlValue } from "../../shared/types/valid-url.value";



@Injectable({
	providedIn: 'root'
})
export class FiltersV2Service {
	storageKey: string;
	reloadActives$ = new BehaviorSubject(null);

	activeFilters$: Observable<number> = this.reloadActives$.pipe(
		map(() => this.getActiveFiltersCount()),
		cast<number>()
	);

	private activeFilters: FilterFieldInfo[] = [];

	constructor(
		private routerService: RouterService,
		private filtersService: FiltersService,
	) {
	}

	get filter$() {
		return this.filtersService._filter$;
	}

	watchSearch() {
		return this.filtersService._search.asObservable();
	}

	watchActives() {
		return this.reloadActives$.pipe(
			map(() => this.getActiveFiltersCount()),
		);
	}

	open() {
		this.filtersService.open();
	}

	sendFilters(filters: Filter, storageKey: string): void {
		this.storageKey = storageKey;

		this.filter$.next({
			useV2: true,
			...filters
		});

		this.putStoredOnFilters();
	}

	async search() {
		this.saveActiveFilters();
		await this.putFiltersOnUrl();

		this.filtersService._search.next(null);
	}

	getActiveFiltersParams() {
		const form = this.filter$.value.form;
		const params: Record<string, string> = {};

		this.activeFilters.forEach((info) => {
			params[info.name] = form.get(info.name)?.value;
		});

		return params;
	}

	getActiveFiltersCount() {
		return this.activeFilters.length;
	}

	isValidValue(value: unknown): value is ValidUrlValue {
		if (typeof value === "number") return value !== 0;
		if (typeof value === "boolean") return true;
		if (typeof value === "string") return !!value;
		if (Array.isArray(value)) return value.length !== 0;
		if (value instanceof Date) return true;

		return value !== undefined && value !== null;
	}

	private async putStoredOnFilters() {
		const fromUrl = this.routerService.getQueryParams();
		const form = this.filter$.value.form;

		const all = {
			...fromUrl
		};

		Object.entries(all).forEach(([key, value]) => form.get(key)?.setValue(value));

		this.saveActiveFilters();
		await this.putFiltersOnUrl();
	}

	private saveActiveFilters() {
		const form = this.filter$.value.form;
		const fieldsInfo = this.getFieldsInfo();

		this.activeFilters = fieldsInfo.filter(info => {
			const value = form.get(info.name)?.value;

			return this.isValidValue(value);
		});

		this.reloadActives$.next(null);
	}

	private getFieldsInfo() {
		const fields = this.filter$.value.fields;
		const fieldsInfo: FilterFieldInfo[] = [];

		fields.forEach(field => {
			if (field.startUrlParameter) fieldsInfo.push({
				field,
				name: field.startName!,
			});
			if (field.endUrlParameter) fieldsInfo.push({
				field,
				name: field.endName!,
			});
			if (field.urlParameter) fieldsInfo.push({
				field,
				name: field.name!,
			});
		});

		return fieldsInfo;
	}

	private async putFiltersOnUrl() {
		const form = this.filter$.value.form;

		const queryParams = await this.routerService.getQueryParams();

		await this.routerService.setQueryParams({...queryParams, ...form.getRawValue()});
	}




}
