import { HttpHeaders, HttpResponse } from "@angular/common/http";
import { EventEmitter } from "@angular/core";

import { Generic } from "../../shared/models/generic";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { User } from "../../modules/users/models/user";


export class Authentication {


	static user: User|null;
	static nameProperty = 'headers';
	static accessToken = 'access-token';
	static propertyParameters: Generic = {
		uid: '',
		[Authentication.accessToken]: '',
		client: '',
		platform: '1',
		// eslint-disable-next-line @typescript-eslint/naming-convention
		'default-key-transform': 'camel_lower'

	};
	static headers = new HttpHeaders(Authentication.propertyParameters);
	public static userChangedSuject = new EventEmitter<User | null>();

	static getHeadersAsObject(): Generic {
		return Authentication.getCurrentHeadersObjectByKeys(Authentication.headers.keys());
	}

	static isThereAccessToken(): boolean {
		Authentication.getHeaders();


		return !!Authentication.headers.get(Authentication.accessToken);
	};

	static getCurrentHeadersObjectByKeys(keys: string[]): Generic {
		const headersObject: Generic = {};

		keys.forEach(key => headersObject[key] = Authentication.headers.get(key));

		return headersObject;
	};

	static getHeaders(): HttpHeaders {
		const headersFromLocalStorage = JSON.parse(
			localStorage?.getItem(Authentication.nameProperty)?.toString?.() || '{}'
		) as Generic;

		Authentication.setHeaders(headersFromLocalStorage);

		return Authentication.headers;
	};

	static setHeaders(newHeaders: Generic = {}): HttpHeaders {
		const nextHeaders = Object.assign(Authentication.getHeadersAsObject(), newHeaders);

		for (const property in nextHeaders) {
			if (nextHeaders.hasOwnProperty(property)) {
				if (!Auxiliary.isUndefined(nextHeaders[property]) && !Auxiliary.isNull(nextHeaders[property])) {
					Authentication.headers = Authentication.headers.set(property, nextHeaders[property]);
				} else {
					Authentication.headers = Authentication.headers.delete(nextHeaders[property]);
					delete nextHeaders[property];
				}
			}
		}

		localStorage.setItem(Authentication.nameProperty, JSON.stringify(nextHeaders));

		return Authentication.headers;
	};

	static createHeadersByResponse(
		response: HttpResponse<any>,
		propertyKeys = Object.keys(Authentication.propertyParameters)
	): Generic {
		const newHeaders: Generic = {};

		propertyKeys.forEach(item => {
			const value = response.headers.get(item);

			if (value) newHeaders[item] = value;
		});

		return newHeaders;
	};

	static resetHeaders(): void {
		Authentication.setHeaders({uid: '', [Authentication.accessToken]: '', client: ''});
	}

	static setUser(user: User|null): void{
		Authentication.user = user;
		Authentication.userChangedSuject.next(user);
	}

	static getUser() {
		return Authentication.user;
	}

	static get isLogged() {
		return !!Authentication.user;
	}
}
