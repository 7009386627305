<a class="avatar"
   *ngIf="link; else withoutLink"
   [routerLink]="link | translate"
   matRipple
   [attr.aria-hidden]="!text"
   [matRippleCentered]="true"
   [target]="target"
   [queryParamsHandling]="queryParamsHandling"
   [class.avatar--inherit-cursor]="inheritCursor"
   [class.avatar--transparent-background-color]="transparentBackgroundColor"
   [matTooltip]="tooltip | translate">
    <ng-container *ngTemplateOutlet="content"></ng-container>
</a>

<ng-template #withoutLink>
    <div class="avatar avatar--no-action"
         [attr.aria-hidden]="!text"
         [class.avatar--inherit-cursor]="inheritCursor"
         [class.avatar--transparent-background-color]="transparentBackgroundColor"
         [matTooltip]="tooltip | translate">
        <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
</ng-template>

<ng-template #content>
    <div [ngStyle]="{'background-image': 'url(' + imageUrl + ')'}"
         [class]="classes"
         [ngClass]="{'avatar__link--is-not-there': !imageUrl}">
        <span *ngIf="text" class="avatar__text">{{ text | translate }}</span>
        <app-icon *ngIf="!imageUrl" [icon]="icon" classes="avatar__icon"></app-icon>
    </div>
</ng-template>
