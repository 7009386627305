import { createReducer, on } from '@ngrx/store';

import { userActions } from '../actions';
import { User } from "../../../modules/users/models/user";

export interface State {
    current?: User;
}

export const initialState: State = {};

export const reducer = createReducer(
    initialState,
    on(userActions.setCurrent, (state, {payload}) => ({...state, current: payload})),
);

