import { ElementRef, Injectable } from '@angular/core';
import { FormControl } from "@angular/forms";
import { MatInput } from "@angular/material/input";
import { MatEndDate, MatStartDate } from "@angular/material/datepicker";

import { fromEvent, Subscription } from "rxjs";
import { MaskApplierService, MaskPipe } from "ngx-mask";
import * as moment from "moment";
import { Moment } from "moment";

@Injectable({
    providedIn: 'root'
})
export class FormDateService {
    maskPipe = new MaskPipe(this.maskService);

    constructor(private maskService: MaskApplierService){}

    onChange(input: HTMLInputElement, formatDate: string, field: FormControl): void{
        const value = input?.value;

        if(value?.replaceAll?.('/', '')?.length === formatDate?.replaceAll?.('/', '')?.length){
            const maskValue = this.maskPipe.transform(value, formatDate?.replace(/[A-Za-z]/g, '0'));
            let dateString = maskValue?.split('/')?.reverse()?.join('-');

            if(!/^\d{4}/.test(dateString)) dateString = `${new Date().getFullYear()}-${dateString}`;

            const date = moment(dateString)?.toDate();

            if(!isNaN(date?.getDate())) field.setValue(date);
        } else if(!value) field.setValue(null);
    }

    watchInputChange(input: ElementRef, matInputDate: MatInput|MatEndDate<any>|MatStartDate<any>): Subscription{
        // eslint-disable-next-line no-underscore-dangle
        return fromEvent(input.nativeElement, 'input').subscribe(() => matInputDate._onInput(input.nativeElement.value));
    }

    setMask(formatDate: string): (string|RegExp)[]{
        let mask: (string|RegExp)[] = [];

        switch(formatDate.length){
            case 5:
                mask = [/\d/, /\d/, '/', /\d/, /\d/];
                break;
            case 10:
                mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
                break;
        }

        return mask;
    }

    chosenYearHandler(normalizedYear: Moment, date: FormControl) {
        const ctrlValue = date.value;

        ctrlValue.year(normalizedYear.year());

        date.setValue(ctrlValue);
    }

    chosenMonthHandler(normalizedMonth: Moment, date: FormControl) {
        const ctrlValue = date.value;

        ctrlValue.month(normalizedMonth.month());

        date.setValue(ctrlValue);
    }
}
