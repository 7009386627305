import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDrawerMode } from '@angular/material/sidenav';
import { Router } from '@angular/router';

import { fromEvent, Observable, Subscription } from 'rxjs';
import { debounceTime, delay, startWith } from "rxjs/operators";
import { Store } from "@ngrx/store";

import { MenuService } from "./menu.service";
import { MenuOrderPipe } from "../../shared/pipes/menu-order/menu-order.pipe";
import { FormButton } from "../../shared/models/form-button";
import { environment } from "../../../environments/environment";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { LocalActionsService } from "../local-actions/local-actions.service";
import { AppService } from "../../app.service";
import { FiltersService } from "../filters/filters.service";
import { menuSelectors } from "../../shared/store/selectors";
import { Translate } from "../../shared/helpers/translate";
import { menuActions } from "../../shared/store/actions";
import { Authentication } from "../authentication/authentication";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss'],
    preserveWhitespaces: false,
    providers: [
        {
            provide: MenuOrderPipe,
            useClass: MenuOrderPipe
        },
    ]
})
export class MenuComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('toolbar', {read: ElementRef}) toolbar: ElementRef;
    mode: MatDrawerMode = 'side';
    createAction: FormButton = {} as FormButton;
    version = `v ${environment.appVersion}`;

    TOOLBAR_HEIGHT = 56;
    menuList$ = this.store.select(menuSelectors.selectMenusGrouped);

    openedState$ = this.store.select(menuSelectors.selectShouldBeOpen);
    private resizeObservable: Observable<Event>;
    private subscriptions: Subscription[] = [];

    constructor(
        private changeDetector: ChangeDetectorRef,
        private store: Store,
        private elementRef: ElementRef<HTMLElement>,
        private router: Router,
        public menuServ: MenuService,
        private filtersService: FiltersService,
        public rootServ: AppService,
        private menuOrder: MenuOrderPipe,
        private actionsService: LocalActionsService
    ) {
    }

    clickItem(): void {
        this.store.dispatch(menuActions.clickOnItem());
    }

    close() {
        this.store.dispatch(menuActions.setDisplayState({payload: 'closed'}));
    }

    ngOnInit(): void {
        this.setToolbarHeight();
        this.resizeObservable = fromEvent(window, 'resize').pipe(debounceTime(250));
        this.subscriptions.push(
            this.resizeObservable.subscribe(() => this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit())),
            this.menuServ.reorderMenu.asObservable().pipe(startWith(true)).subscribe(condition =>
                condition
                    ? this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit())
                    : null),
            this.actionsService.watchCreateAction().pipe(delay(25)).subscribe((createAction: FormButton) => {
                this.createAction = createAction;
                this.changeDetector.detectChanges();
            }),
        );
    }

    ngAfterViewInit(): void {
        this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit());
    }

    get canISeeMenu(): boolean {
        const notFoundUrl = Translate.value('routes.notFound.path').replace('/', '');
        const noAccessUrl = Translate.value('routes.noAccess.path').replace('/', '');
        const loginUrl = Translate.value('routes.login.path').replace('/', '');

        const isAdminRoute = this.router.url.replace("/", "").startsWith(Translate.value('routes.allRoutePrefix.admin'));
        const isAccountRoute = this.router.url.replace("/", "").startsWith(Translate.value('routes.allRoutePrefix.account'));

        const routesWithoutMenu = [notFoundUrl, loginUrl, noAccessUrl];
        return !routesWithoutMenu.find(item => this.router.url.includes(item)) &&
            (isAdminRoute || isAccountRoute);
    }

    get canISeeToolbar(): boolean {
        return Authentication.isLogged
    }

    get top() {
        return this.canISeeToolbar ? this.TOOLBAR_HEIGHT + "px" : "0px";
    }

    changeMenuConfigurations(hideMenu: boolean): void {
        this.mode = hideMenu ? 'over' : 'side';


        this.changeDetector.detectChanges();
    }

    reorderMenu(): void {
        setTimeout(() => {
            this.changeMenuConfigurations(this.menuServ.isBiggerThanLimit());
        });
    }


    ngOnDestroy(): void {
        Auxiliary.unsubscribeAll(this.subscriptions);
    }

    setToolbarHeight() {
        const element = this.elementRef.nativeElement;
        element.style.setProperty('--toolbar-height', this.TOOLBAR_HEIGHT + 'px');
    }
}
