import { Directive, OnDestroy } from "@angular/core";

import { Observable, Subject, Subscription } from "rxjs";

import { Auxiliary } from "../helpers/auxiliary";

@Directive()
export class BaseSubscriber implements OnDestroy{
    protected subscriptions: Subscription[] = [];
    protected destroyRef$ = new Subject();

    ngOnDestroy(): void {
        this.unsubscribeAll();
    }

    protected unsubscribeAll(){
        Auxiliary.unsubscribeAll(this.subscriptions);
        this.destroyRef$.next(null);
        this.destroyRef$.complete();
    }

    protected subscribe(...observables: Observable<any>[]){
        this.subscriptions.push(...observables.map(obs => obs.subscribe()));
    }
}
