import { Injectable } from '@angular/core';
import { ActivatedRoute, Params } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup } from "@angular/forms";

import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { LocalActionsService } from "../local-actions/local-actions.service";
import { map, pluck } from "rxjs/operators";
import { cast } from "../../shared/rxjs-operators";
import { Filter } from "./interfaces/filter";
import { Generic } from "../../shared/models/generic";
import { LocalAction } from "../local-actions/interfaces/local-action";
import { FilterField } from "./interfaces/filter-field";
import { Translate } from "../../shared/helpers/translate";

@Injectable({
    providedIn: 'root'
})
export class FiltersService {
    filter$: Observable<Filter>;
    canShowFilter$: Observable<boolean>;
    onSearch$: Observable<null>;
    onClearAll$: Observable<null>;

    _filter$ = new BehaviorSubject<Filter>({
        form: this.fb.group({}),
        fields: [],
        component: {}
    });

    _search = new Subject<null>();
    private toggleSubject = new Subject<any>();
    private _clearAll = new Subject<null>();
    private closeSubject = new Subject<any>();
    private activesBehaviorSubject = new BehaviorSubject<number>(0);


    constructor(
        private activatedRoute: ActivatedRoute,
        private localActions: LocalActionsService,
        private fb: FormBuilder,
    ) {
        this.canShowFilter$ = this.localActions.watchActions().pipe(
            cast<LocalAction>(),
            pluck('localActions'),
            map(actions => !!actions?.find(filter => filter.icon === 'filter_list')),
        );

        this.filter$ = this._filter$.asObservable();
        this.onSearch$ = this._search.asObservable();
        this.onClearAll$ = this._clearAll.asObservable();
    }

    watchFilters(): Observable<Filter> {
        return this._filter$.asObservable();
    }

    sendFilters(filters: Filter): void {
        this._filter$.next(filters);
    }

    onToggle(): Observable<any> {
        return this.toggleSubject.asObservable();
    }

    open(): void {
        this.toggleSubject.next(null);
    }

    onClose(): Observable<any> {
        return this.closeSubject.asObservable();
    }

    close(): void {
        this.closeSubject.next(null);
    }

    watchActives(): Observable<number> {
        return this.activesBehaviorSubject.asObservable();
    }

    search() {
        this._search.next(null);
    }

    clearAll() {
        this._clearAll.next(null);
    }

    sendActives(actives: number): void {
        this.activesBehaviorSubject.next(actives);
    }

    removeFiltersFields(parameters: Params, form: FormGroup): Params {
        const newParameters: Params = {};
        const fieldKeys = Object.keys(form.getRawValue());

        for (const parameter in parameters)
            if (parameters.hasOwnProperty(parameter) && !fieldKeys.includes(parameter))
                newParameters[parameter] = parameters[parameter];

        return newParameters;
    }

    getFiltersOnURL(fields: FilterField[]): Params {
        const filters: Params = {};
        const filtersUrlNames: Generic[] = [];

        fields.forEach(field => {
            if (field.startUrlParameter) filtersUrlNames.push({
                name: field.startName,
                urlParameter: field.startUrlParameter
            });
            if (field.endUrlParameter) filtersUrlNames.push({name: field.endName, urlParameter: field.endUrlParameter});
            if (field.urlParameter) filtersUrlNames.push({name: field.name, urlParameter: field.urlParameter});
        });

        this.activatedRoute
            .queryParams
            .subscribe(parameters =>
                filtersUrlNames.forEach(filter => {
                    const key = Translate.value(filter.urlParameter);
                    const value = parameters[key];
                    if (value) filters[filter.name] = value;
                })
            );

        return filters;
    }

    getDefaultFiltersOnForm(fields: FilterField[], form: FormGroup) {
        let params: Params = {};

        fields.forEach(filter => {
            const control = form?.get(filter.name ?? "");
            const startControl = form?.get(filter.startName ?? "");
            const endControl = form?.get(filter.endName ?? "");

            params = this.getParamsWithFilterValue(control!, filter.name!, params);
            params = this.getParamsWithFilterValue(startControl!, filter.startName!, params);
            params = this.getParamsWithFilterValue(endControl!, filter.endName!, params);
        });

        return params;
    }

    private getParamsWithFilterValue(control: AbstractControl, name: string, params: Generic) {
        let value = control?.value;


        if(this.isNullableFilter(value)) return params;

        if(Array.isArray(value)) value = value.toString();

        return {
            ...params,
            [name]: value
        };
    }

    private isNullableFilter(value: any){
        if(value === 0 || value === "") return true;
        if(value === undefined || value === null) return true;

        return value?.length === 0;
    }
}
