export const ROUTES_TRANSLATIONS = {

    routes: {
        breadcrumbs: {
            new: "Adicionar",
            update: "Editar",
            visualization: "Visualizar",
        },
        metas: {
            new: "",
            update: "",
            default: "",
            visualization: "",
            single: ":id",
        },

        genericRoutes: {
            new: 'new',
            empty: '',
            single: ':id'
        },
        allRoutePrefix: {
            configurations: 'configurations',
            admin: 'admin',
            account: "account"
        },

        configurations: {
            path: '/configurations',
            default: '',
            data: {
                breadcrumbName: "Configurações",
                metas: {}
            }
        },

        // ROUTES CORE
        myProfile: {
            path: "/my-profile",
            admin: {
                path: "/admin/my-profile"
            },

            account: {
                path: "/account/my-profile"
            },
            default: "",
            data: {
                breadcrumbName: "Meu perfil",
                metas: {}
            }
        },
        passwordReset: {
            path: "/forgot-my-password",
            default: "",
            data: {
                breadcrumbName: "Esqueci minha senha",
                metas: {}
            }
        },
        passwordEdit: {
            path: "/change-password",
            default: "",
            data: {
                breadcrumbName: "Alterar senha",
                metas: {}
            }
        },

        admin:{
            accounts:{
                path: "/admin/accounts",
                data: {
                    breadcrumbName: "Contas",
                    metas: {}
                },

                permissions: {
                    path: ":id/users/:userId/permissions"
                }
            },
            login: {
                path: "/admin/login",
                empty: "",
                nextPath: "proximo-caminho",
                default: "",
                data: {
                    breadcrumbName: "Entrar",
                    metas: {}
                },
            },
        },
        account:{
            users:{
                path: "/account/users",
                data: {
                    breadcrumbName: "Usuários",
                    metas: {}
                },
            },
            complaints:{
                path: "/account/complaints",
                data: {
                    breadcrumbName: "Denúncias",
                    metas: {}
                },
            },
            categories:{
                path: "/account/categories",
                data: {
                    breadcrumbName: "Categorias",
                    metas: {}
                },
            },
            committees:{
                path: "/account/committees",
                data: {
                    breadcrumbName: "Comitês",
                    metas: {}
                },
            },
        },


        login: {
            path: "/login",
            empty: "",
            nextPath: "proximo-caminho",
            default: "",
            data: {
                breadcrumbName: "Entrar",
                metas: {}
            },


        },

        home: {
            path: "/",
            empty: "",
            nextPath: "proximo-caminho",
            default: "",
            data: {
                breadcrumbName: "Início",
                metas: {}
            }
        },

        welcome: {
            path: "/welcome",
            empty: "",
            default: "",
            data: {
                breadcrumbName: "Bem vindo",
                metas: {}
            }
        },

        complaintsMenu: {
            path: "/complaints-menu",
            empty: "",
            default: "",
            data: {
                breadcrumbName: "Denúncias",
                metas: {}
            },
            followUp: {
                path: "follow-up",
                fullPath: "/complaints-menu/follow-up",
                data: {
                    breadcrumbName: "Consultar",
                    metas: {}
                },
            },

            single: {
                path: "follow-up/:id",
                data: {
                    breadcrumbName: "Consultar",
                    metas: {}
                },
            },
            new: {
                data: {
                    breadcrumbName: "Nova",
                    metas: {}
                },
            },
            womanChannel: {
                path: "woman-channel",
                data: {
                    breadcrumbName: "Canal da mulher",
                    metas: {}
                },
            }
        },
        notFound: {
            path: "/not-found",
            anything: "**",
            default: "",
            data: {
                breadcrumbName: "Página não encontrada",
                metas: {}
            }
        },
        noAccess: {
            path: "/no-access-permission",
            default: "",
            data: {
                breadcrumbName: "Sem permissão de acesso",
                metas: {}
            }
        },
        charges:{
            path: "/charges",
            data: {
                breadcrumbName: "Cobranças",
                metas: {}
            }
        }
    },
};
