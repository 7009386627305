<mat-form-field [appearance]="appearance"
                [hintLabel]="hintLabel | translate"
                class="datepicker"
                [class.datepicker--with-icon]="iconTooltip && tooltip"
                [formGroup]="form"
                [floatLabel]="floatLabel">
    <mat-label>{{ label | translate }}</mat-label>
    <input matInput
           [matDatepicker]="datepicker"
           aria-label=""
           [required]="required"
           [matDatepickerFilter]="filter"
           autocomplete="off"
           #dateInput
           [appFormDate]="textMaskConfig"
           (dateInput)="dateServ.onChange(dateInput, formatDate, field)"
           (dateChange)="dateServ.onChange(dateInput, formatDate, field)"
           [attr.aria-label]="label | translate"
           [name]="name"
           [formControlName]="name"
           [placeholder]="placeholder | translate">
    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
    <mat-datepicker #datepicker
                    [startView]="startView"
                    (yearSelected)="onYearSelected($event, datepicker, date)"
                    (monthSelected)="onMonthSelected($event, datepicker, date)"></mat-datepicker>
    <mat-icon aria-hidden="true" matSuffix *ngIf="icon">{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>

<mat-icon aria-hidden="true" [matTooltip]="tooltip | translate" class="datepicker__icon" *ngIf="iconTooltip && tooltip">{{iconTooltip}}</mat-icon>
