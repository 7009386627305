export const FORCE_CHANGE_PASSWORD_TRANSLATE = {
	forceChangePassword: {
		title: "Troca de senha",
		subtitle: "Por motivos de segurança, é necessário trocar a sua senha para continuar",
		fields: {
			currentPassword: {
				label: "Senha atual",
				placeholder: "Informe a senha atual",
			},
			password: {
				label: "Nova senha",
				placeholder: "Informe a nova senha",
				hint: "A senha deve conter caracteres especiais, maiúsculos, minúsculos e números"
			},
			passwordConfirmation: {
				label: "Confimação de nova senha",
				placeholder: "Informe a confirmação de nova senha",
				hint: "A confirmação de senha deve ser igual à nova senha"
			},
		},
		actions: {
			update: "Alterar",
			close: "Fechar e sair"
		},
	}
};
