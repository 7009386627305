import { Injectable } from "@angular/core";
import {
	ForceChangePasswordDialogService
} from "../../../modules/force-change-password/force-change-password-dialog.service";

@Injectable({
	providedIn: "root",
})
export class MandatoryDialogsService {
	constructor(private forceChange: ForceChangePasswordDialogService) {
	}

	verifyDialogs() {
		if(this.forceChange.shouldOpen())
			this.forceChange.open();
	}
}
