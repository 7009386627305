import {Inject, Optional, Pipe, PipeTransform} from '@angular/core';
import { Translate } from '../../helpers/translate';
import {Generic} from "../../models/generic";
import {ROOT_TRANSLATION} from "./root-translation.token";

@Pipe({
    name: 'translate'
})
export class RootTranslatePipe implements PipeTransform {
    constructor(@Optional() @Inject(ROOT_TRANSLATION) private rootTranslation?: string) {
    }

    transform(value: string, payload?: Generic, options?: Generic): unknown {
        const path = (this.rootTranslation ?? '') + value;
        return Translate.value(path, options, payload);
    }
}
