<button #fab
        class="fab mdc-fab mdc-fab--extended fab"
        matRipple
        *ngIf="!href"
        matRippleColor="rgba(255, 255, 255, 0.12)"
        [matTooltip]="fab.offsetWidth <= 60 ? (text | translate) : ''">
    <ng-container *ngTemplateOutlet="into"></ng-container>
</button>

<a #fab
   class="fab mdc-fab mdc-fab--extended fab"
   matRipple
   *ngIf="href"
   [routerLink]="href | translate"
   matRippleColor="rgba(255, 255, 255, 0.12)"
   [matTooltip]="fab.offsetWidth <= 60 ? (text | translate) : ''">
    <ng-container *ngTemplateOutlet="into"></ng-container>
</a>

<ng-template #into>
    <div aria-hidden="true" class="mdc-fab__ripple"></div>
    <span aria-hidden="true" class="material-icons mdc-fab__icon fab__icon" *ngIf="icon">{{ icon }}</span>
    <span class="mdc-fab__label fab__text" *ngIf="text">{{ text | translate }}</span>
</ng-template>

