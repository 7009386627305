import { Component, Input } from '@angular/core';
import { FormBadge } from "../../../../models/form-badge";


@Component({
    selector: 'app-form-small-content',
    templateUrl: './form-small-content.component.html',
    styleUrls: ['./form-small-content.component.scss']
})
export class FormSmallContentComponent{
    @Input() text = '';
    @Input() icon = '';
    @Input() svgIcon = '';
    @Input() iconClasses = '';
    @Input() badge: FormBadge;
}
