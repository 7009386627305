import { Component, Input } from '@angular/core';
import { ThemePalette } from "@angular/material/core";
import { FormBadge } from "../../../models/form-badge";
import { Auxiliary } from "../../../helpers/auxiliary";

@Component({
    selector: 'app-icon',
    templateUrl: './icon.component.html',
    styleUrls: ['./icon.component.scss']
})
export class IconComponent{
    @Input() icon = '';
    @Input() svgIcon = '';
    @Input() classes = '';
    @Input() color: ThemePalette|string = undefined;
    badge: FormBadge = {
        color: 'warn',
        position: 'above after',
        size: 'small',
        overlap: true,
        disabled: false,
        description: ''
    };

    @Input('badge')
    set setBadge(badge: FormBadge){
        if(badge) this.badge = { ...this.badge, ...badge, ...{ value: Auxiliary.createObservable(badge.value) } };
    }
}
