<div data-global-loading class="global-loading" [ngClass]="globalLoading.show ? 'global-loading--show' : 'global-loading--hide'" aria-hidden="true" tabindex="-1">
    <app-progress-spinner mode="indeterminate"
                            [strokeWidth]="6"
                            aria-hidden="true"
                            class="global-loading__loading"
                            [diameter]="60">
    </app-progress-spinner>

    <p aria-hidden="true" class="global-loading__text" *ngIf="globalLoading.message">{{ globalLoading.message | translate }}</p>
</div>

