import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { MenuBreadcrumb } from "../../../shared/models/menu-breadcrumb";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";

@Injectable({
	providedIn: 'root'
})
export class MenuBreadcrumbService {
	public onToggle = new Subject<boolean>();
	private breadcrumbList: MenuBreadcrumb[] = [];

	constructor(
		private store: Store,
		private router: Router,
	) {
	}

	set breadcrumb(breadcrumb: MenuBreadcrumb[]) {
		this.breadcrumbList = breadcrumb;
	}

	get breadcrumb(): MenuBreadcrumb[] {
		return this.breadcrumbList;
	}

	get antepenultimateItem(): MenuBreadcrumb {
		return this.breadcrumbList?.[this.breadcrumbList.length - 2];
	}

	get lastItem(): MenuBreadcrumb {
		return this.breadcrumbList?.[this.breadcrumbList.length - 1];
	}

	goToLastBreadcrumb() {
		// const all = subscribeOnce(this.store.select(breadcrumbSelectors.all)) ?? [];
		// const penultimate = all[all.length - 2];
		//
		// if (!penultimate) return;

		this.router.navigateByUrl("penultimate.url");
	}

	setActiveBreadcrumb(breadcrumb: MenuBreadcrumb): void {
		const lastItem = this.breadcrumbList?.[this.breadcrumbList.length - 1];

		if (lastItem) this.breadcrumbList[this.breadcrumbList.length - 1] = Object.assign(lastItem, breadcrumb);
	}

	public ToggleMenu(shouldOpen?: boolean) {
		this.onToggle.next(!!shouldOpen);
	}
}
