import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appVar]'
})
export class VarDirective implements OnInit{
    @Input()
    set appVar(context: any) {
        this.context.$implicit = this.context.appVar = context;
    }

    context: any = {};

    constructor(private macRef: ViewContainerRef, private templateRef: TemplateRef<any>){}

    ngOnInit(): void {
        this.macRef.clear();
        this.macRef.createEmbeddedView(this.templateRef, this.context);
    }
}
