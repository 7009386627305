<div *ngIf="condition">
    <ng-container [ngSwitch]="theme" *ngIf="!href">
        <button [class]="classes"
                mat-button
                *ngSwitchCase="'basic'"
                [matTooltip]="tooltip | translate"
                [type]="type"
                (click)="onClick($event)"
                #button
                [ngClass]="{'button__wait': loading}"
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [loading]="loading"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'raised'"
                [class]="classes"
                [type]="type"
                mat-raised-button
                #button
                (click)="onClick($event)"
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [matTooltip]="tooltip | translate"
                [loading]="loading"
                [ngClass]="{'button__wait': loading}"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'stroked'"
                [class]="classes"
                mat-stroked-button
                [type]="type"
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [ngClass]="{'button__wait': loading}"
                #button
                [loading]="loading"
                (click)="onClick($event)"
                [matTooltip]="tooltip | translate"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'flat'"
                [class]="classes"
                mat-flat-button
                [type]="type"
                #button
                [matTooltip]="tooltip | translate"
                [loading]="loading"
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [ngClass]="{'button__wait': loading}"
                (click)="onClick($event)"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'icon'"
                [class]="classes"
                mat-icon-button
                [type]="type"
                #button
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [loading]="loading"
                (click)="onClick($event)"
                [ngClass]="{'button__wait': loading}"
                [matTooltip]="tooltip | translate"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [icon]="icon" [svgIcon]="svgIcon" [badge]="badge"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'fab'"
                [class]="classes"
                [type]="type"
                mat-fab
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                #button
                [matTooltip]="tooltip | translate"
                [loading]="loading"
                (click)="onClick($event)"
                [ngClass]="{'button__wait': loading}"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [ngStyle]="styleConfig"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>

        <button *ngSwitchCase="'mini-fab'"
                [class]="classes"
                [type]="type"
                #button
                mat-mini-fab
                (keydown.enter)="onClick($event)"
                (keydown.space)="onClick($event)"
                [ngClass]="{'button__wait': loading}"
                (click)="onClick($event)"
                [matTooltip]="tooltip | translate"
                [loading]="loading"
                [disabled]="disabled?.call(this)"
                [color]="color"
                [ngStyle]="styleConfig"
                [attr.tabindex]="tabindexBtn"
                [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </button>
    </ng-container>

    <ng-container [ngSwitch]="theme" *ngIf="href">
        <a [class]="classes"
           mat-button
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           *ngSwitchCase="'basic'"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [routerLink]="href | translate"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'raised'"
           [class]="classes"
           mat-raised-button
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [routerLink]="href | translate"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'stroked'"
           [class]="classes"
           mat-stroked-button
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [routerLink]="href | translate"
           [href]="href"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'flat'"
           [class]="classes"
           mat-flat-button
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [routerLink]="href | translate"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [text]="text" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'icon'"
           [class]="classes"
           mat-icon-button
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [routerLink]="href | translate"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [icon]="icon" [svgIcon]="svgIcon" [badge]="badge"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'fab'"
           [class]="classes"
           mat-fab
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [ngStyle]="styleConfig"
           [routerLink]="href | translate"
           [target]="target"
           (click)="onClick($event)"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [svgIcon]="svgIcon" [icon]="icon"></app-form-small-content>
        </a>

        <a *ngSwitchCase="'mini-fab'"
           [class]="classes"
           mat-mini-fab
           [queryParams]="queryParams"
           [queryParamsHandling]="queryParamsHandling"
           [matTooltip]="tooltip | translate"
           [disabled]="disabled?.call(this)"
           [color]="color"
           [ngStyle]="styleConfig"
           [routerLink]="href | translate"
           [target]="target"
           [attr.tabindex]="tabindexBtn"
           [attr.aria-label]="text | translate">
            <app-form-small-content iconClasses="button__icon" [icon]="icon"></app-form-small-content>
        </a>
    </ng-container>
</div>
