import { Menu } from "../../models/menu";
import { ArraysHelper } from "../arrays-helpers";

export class MenuUtils {
    static sortGroup(groups: { name: string; list: Menu[] }[], exceptions: { groupName: string; customSort: (a: string, b: string) => number }[] = []) {
        const sortedByGroups = groups.sort((a, b) => {
            const exception = exceptions.find(exce =>
                exce.groupName === a.name || exce.groupName === b.name);

            if (!!exception) return exception.customSort(a.name, b.name);

            return a.name.localeCompare(b.name);
        });

        return sortedByGroups.map(group => ({
            ...group,
            list: group.list.sort((a, b) => a.name.localeCompare(b.name))
        }));
    };

    static validateMenu(menu: Menu[]){
        return menu.filter(item => item.show)
    }

    static groupMenus(menus: Menu[]) {
        return ArraysHelper.groupBy(menus, menu => menu.group);

    };
}

