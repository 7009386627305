import { Component } from '@angular/core';
import { BACK_BUTTON, SAVE_BUTTON } from "../../shared/default-variables/actions";
import { DialogService } from "../../shared/components/default/dialog/dialog.service";
import { FormBuilder, Validators } from "@angular/forms";
import { FormService } from "../../shared/components/default/form/form.service";
import { ForceChangePasswordService } from "./force-change-password.service";
import { SignInService } from "../sign-in/sign-in.service";

@Component({
	selector: 'app-force-change-password',
	templateUrl: './force-change-password.component.html',
	styleUrls: ['./force-change-password.component.scss']
})
export class ForceChangePasswordComponent {
	principalTitle = "forceChangePassword.title";
	subtitle = "forceChangePassword.subtitle";

	reset = BACK_BUTTON(() => this.close(), {
		text: "forceChangePassword.actions.close"
	});
	save = SAVE_BUTTON({
		text: "forceChangePassword.actions.update"
	});

	form = this.fb.group({
		currentPassword: [null, Validators.required],
		password: [null, Validators.required],
		passwordConfirmation: [null, Validators.required],
	});
	fs = FormService;

	constructor(
		private dialog: DialogService,
		private fb: FormBuilder,
		private service: ForceChangePasswordService,
		private signIn: SignInService
	) {
	}

	onSubmit() {
		this.service.changePassword(this.form.getRawValue())
			.subscribe(() => this.dialog.close());
	}

	close(){
		this.dialog.close();
		this.signIn.logout();
	}
}
