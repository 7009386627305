import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RootTranslatePipe} from "./root-translate.pipe";

@NgModule({
    declarations: [RootTranslatePipe],
    imports: [CommonModule],
    exports: [RootTranslatePipe]
})
export class RootTranslateModule {
}
