import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';

import { TranslateModule } from "@ngx-translate/core";

import { SnackBarService } from "./snack-bar.service";
import { SnackBarComponent } from './snack-bar.component';
import { FormModule } from "../form/form.module";

@NgModule({
    declarations: [
        SnackBarComponent
    ],
    imports: [
        CommonModule,
        FormModule,
        TranslateModule
    ],
    exports: [
        SnackBarComponent
    ],
    providers: [
        SnackBarService,
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: {
                horizontalPosition: 'start',
                verticalPosition: 'bottom'
            }
        }
    ]
})
export class SnackBarModule{}
