export const FOLLOW_UP_COMPLAINS_TRANSLATE = {
    followUpComplaints: {
        title: "Digite o número de protocolo para acompanhar sua denúncia",
        form:{
            fields: {
                protocol: {
                    label: "Número do protocolo",
                    placeholder: "Informe aqui o número do protocolo"
                }
            }
        }

    }
}
