import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { map, tap } from "rxjs/operators";
import { Actions, createEffect } from '@ngrx/effects';

import { menuActions } from "../actions";
import { menuSelectors } from "../selectors";
import { FiltersService } from "../../../core/filters/filters.service";

@Injectable({
	providedIn: 'root'
})
export class MenuEffects{
    mirrorSnaphot$ = createEffect(() => { return this.store.select(menuSelectors.selectMenuItems).pipe(
        map(menus => {
			return menuActions.setSnapshot({payload: menus})
		})
    ) });

	closeFilterOnOpenMenu$ = createEffect(() => { return this.store.select(menuSelectors.selectDisplayState).pipe(
		tap(state => {
			if(state === 'open') this.filtersService.close();
		}),
	) }, {dispatch: false});


	constructor(
		private actions$: Actions,
		private filtersService: FiltersService,
		private store: Store,
	) {}
}
