import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params, QueryParamsHandling, Router } from '@angular/router';

import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { Menu } from '../../shared/models/menu';
import { NOTEBOOK_BREAKPOINT } from "../../shared/default-variables/breakpoints";
import { Translate } from "../../shared/helpers/translate";
import { Auxiliary } from "../../shared/helpers/auxiliary";

@Injectable({
    providedIn: "root",
})
export class MenuService {
    watchListSetted = new BehaviorSubject<Menu[]>([]);
    reorderMenu = new Subject<boolean>();
    toggle = new Subject<boolean>();
    close = new Subject<boolean>();
    toggleToolListSubject = new Subject<boolean>();

    private activatedItem: Menu = {} as Menu;
    private menuLoading = false;
    private limit = NOTEBOOK_BREAKPOINT;

    constructor(private router: Router, private http: HttpClient) {
    }

    translateMenuList(menuList: Menu[]): Menu[]{
        return menuList.map(item => ({ ...item, path: Translate.value(item.path) }));
    }

    setActivatedItem(item: Menu): void{
        if(item) this.activatedItem = item;
    }



    chooseHandling(path: string): Observable<QueryParamsHandling>{
        return of(this.router.url.replace(/\?.+/, '') === path ? 'preserve' : '');
    }

    isBiggerThanLimit(width: number = window.innerWidth): boolean{
        return width < this.limit;
    }


    get loading(): boolean{
        return this.menuLoading;
    }

    setLoading(loading: boolean, timeout = 0){
        setTimeout(() => {
			this.menuLoading = loading;
		}, timeout);
    }

    toggleToolList(open: boolean){
        this.toggleToolListSubject.next(open);
    }

    toolbarActions(params: Params): Observable<Params>{

        return this.http.get<Params>(`${environment.api}/tools`, { params: Auxiliary.createHttpParams(params) }).pipe(take(1));
    }



}
