import * as fromRouter from '@ngrx/router-store';
import {routerReducer} from '@ngrx/router-store';
import {ActionReducer, ActionReducerMap, combineReducers} from '@ngrx/store';

import * as menusReducer from './menus';
import * as routesReducer from './routes';
import * as usersReducer from './user';

export interface State{
    route: routesReducer.State;
    routerState: fromRouter.RouterReducerState<any>;
	menuState: menusReducer.State;
    userState: usersReducer.State;

}

const reducers: ActionReducerMap<State> = {
    route: routesReducer.reducer,
	menuState: menusReducer.reducer,
    routerState: routerReducer,
    userState: usersReducer.reducer

};


export const productionReducer: ActionReducer<State> = combineReducers(reducers);
