export class RegexHelper{
	static regexUrl =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
	static regexBaseUrl = /https?:\/\/(www\.)?\w*\.?\w*\.?\w*\.?\w*/;
    static emojiRegex = /(\p{Emoji}\W{0,2})*/giu;
    static emojiRegexBoolean = /\p{Emoji}/giu;
	static emojiAliasRegex = /:[A-Za-z\-\_]*:/gi;

	static matchLinks(text: string) {
		return text.match(RegexHelper.regexUrl);
    }

	static matchBaseUrl(text: string) {
		return text.match(RegexHelper.regexBaseUrl);
    }

	static getBaseUrl(text?: string) {
		if (!text) return '';
		return RegexHelper.matchBaseUrl(text)?.[0].replace(/\/$/, '') ?? '';
	}

	static getEmojis(text = "") {
        text = text.replaceAll(/\d/g, "");
        text = text.replaceAll("#", "");
        text = text.replaceAll("*", "");
        return text.match(this.emojiRegex)?.filter(v=>!!v).map(v=>v.trim()) ?? [];
    }
	static getLinks(text: string) {
        return text.match(this.regexUrl)?.filter(v=>!!v) ?? [];
    }
	static getEmojiAlias(text: string) {
        return text.match(this.emojiAliasRegex)?.filter(v=>!!v) ?? [];
    }

	static hasLink(word: string): boolean {
        return !!word && !!word.match(this.regexUrl);
    }

	static hasEmoji(text = "") {
        text = text.replaceAll(/\d/g, "");
        text = text.replaceAll("#", "");
        text = text.replaceAll("*", "");
        return !!text && !!text.match(this.emojiRegexBoolean);
    }

	static splitBySpecificText(allText: string, specificText: string){
		const identifier = "string-identifier";

		const uniqueIdentifiedText = `<${identifier}>${specificText}</${identifier}>`;

		const allTextWithIdentifiedText = allText?.replace(specificText, uniqueIdentifiedText);

		return allTextWithIdentifiedText?.split(uniqueIdentifiedText) ?? [];
	}

	static removeAllBefore(allText: string, specificText: string){
		return this.splitBySpecificText(allText, specificText)[1];
	}

	static removeAllAfter(allText: string, specificText: string){
		return this.splitBySpecificText(allText, specificText)[0];
	}

	static getAllBetween(allText: string, startText: string, endText: string): string[]{
		const withoutStart = this.removeAllBefore(allText, startText);

		const between = this.removeAllAfter(withoutStart, endText);

		if(!between) return [];

		const newText = allText.replaceAll(`${startText}${between}${endText}`, "");

		return [
			...this.getAllBetween(newText, startText, endText),
			between
		];
	}

	static getRouterSlugAfter(url: string, prefix: string){
		const afterPrefix = this.removeAllBefore(url, prefix);
		const withoutQueryParams = this.removeAllAfter(afterPrefix, "?");

		return withoutQueryParams?.split('/')?.[1];
    }
}

