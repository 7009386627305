import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocalActionsComponent } from './local-actions.component';
import { TranslateModule } from "@ngx-translate/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { FormModule } from "../../shared/components/default/form/form.module";
import { PipesModule } from "../../shared/pipes/pipes.module";

@NgModule({
    declarations: [
        LocalActionsComponent
    ],
    imports: [
        CommonModule,
        FormModule,
        TranslateModule,
        MatTooltipModule,
        PipesModule,
    ],
    exports: [
        LocalActionsComponent
    ]
})
export class LocalActionsModule{}
