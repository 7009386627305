<mat-form-field [appearance]="appearance"
                [hintLabel]="hintLabel | translate"
                class="input"
                [formGroup]="form"
                [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>

    <input [maxlength]="maxlength"
           *ngIf="!isMoney && !mask"
           matInput
           aria-label=""
           #formInputDefault
           autocomplete="chrome-off"
           [attr.aria-label]="label | translate"
           [email]="type === 'email'"
           [type]="getType(type)"
           [attr.inputmode]="inputMode"
           [required]="required"
		   [suffix]="suffix"
           spellcheck="false"
           (input)="upperCase(formInputDefault)"
           (focus)="onFocus($event)"
           [appAutofocus]="autofocus"
           [name]="name"
           [formControlName]="name"
           [placeholder]="placeholder | translate">

    <input [maxlength]="maxlength"
           *ngIf="!isMoney && mask"
           aria-label=""
           matInput
           #formInputMask
           [dropSpecialCharacters]="dropSpecialCharacters"
           [mask]="mask"
           [leadZeroDateTime]="leadZeroDateTime"
           [attr.inputmode]="inputMode"
           autocomplete="off"
           [attr.aria-label]="label | translate"
           [type]="type"
           (keypress)="onKeypress($event)"
           [required]="required"
           (input)="upperCase(formInputMask)"
           (focus)="onFocus($event)"
           spellcheck="false"
		   [suffix]="suffix"
           [appAutofocus]="autofocus"
           [name]="name"
           [formControlName]="name"
           [placeholder]="placeholder | translate">

    <input [maxlength]="maxlength"
           matInput
           #formInputMoney
           aria-label=""
           *ngIf="isMoney && !mask"
           autocomplete="off"
           [attr.aria-label]="label | translate"
           [email]="type === 'email'"
           [type]="getType(type)"
           [required]="required"
           (input)="upperCase(formInputMoney)"
           (focus)="onFocus($event)"
           [attr.inputmode]="inputMode"
           [appAutofocus]="autofocus"
           [name]="name"
           spellcheck="false"
           currencyMask
           [options]="moneyOptions"
           [formControlName]="name"
           [placeholder]="placeholder | translate">

    <mat-icon aria-hidden="true" matSuffix *ngIf="!loading && icon" class="input__icon">{{ icon }}</mat-icon>
    <app-form-button theme="icon"
                    matSuffix
                    color="none"
                    classes="input__password-button"
                    [click]="toggleHide.bind(this)"
                    [text]="hide ? ('form.input.password.show' | translate) : ('form.input.password.hide' | translate)"
                    *ngIf="isPassowordType(type)"
                    [icon]="hide ? 'visibility_off' : 'visibility'">
    </app-form-button>
    <app-progress-spinner mode="indeterminate"
                            [strokeWidth]="2"
                            matSuffix
                            [condition]="loading"
                            class="input__loading"
                            classes="input__progress-spinner"
                            aria-hidden="true"
                            [diameter]="20">
    </app-progress-spinner>
    <span matPrefix *ngIf="showMoneyPrefix()" class="input__money-prefix">{{ moneyPrefix }}&nbsp;</span>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <!-- <mat-hint *ngIf="minlength && minlength > showLengthField()">{{ 'form.errors.minlength' | translate:{ minlength: minlength } }}</mat-hint> -->
    <mat-hint class="input__nowrap" *ngIf="maxlength" align="end">{{ showLengthField() }}/{{ maxlength }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
