<mat-form-field [appearance]="appearance"
                [hintLabel]="hintLabel | translate"
                class="autocomplete"
                [formGroup]="form"
                [floatLabel]="floatLabel">
    <mat-label *ngIf="label">{{ label | translate }}</mat-label>
    <input type="text"
           [placeholder]="placeholder | translate"
           matInput
           #formInput
           aria-label=""
           [appAutofocus]="autofocus"
           (input)="onInput()"
           [attr.aria-label]="label | translate"
           (focus)="onFocus()"
           #trigger="matAutocompleteTrigger"
           autocomplete="off"
           [required]="required"
           [name]="name"
           [formControlName]="name"
           [matAutocomplete]="matAutocomplete">
    <app-form-button
        matSuffix
        (click)="clearField()"
        [disabled]="disabledButton.bind(this)"
        color="primary"
        [loading]="false"
        icon="clear"
        text="form.autocomplete.clear"
        theme="icon"
        tooltip="form.autocomplete.clear"
        type="button">
    </app-form-button>
    <mat-autocomplete #matAutocomplete="matAutocomplete"
                      [displayWith]="displayNameProperty.bind(this)">
        <mat-option *ngIf="!isLoading && !options.length" disabled="true">{{ notFoundMessage | translate }}</mat-option>
        <mat-option *ngIf="isLoading" disabled="true">
            <mat-spinner mode="indeterminate"
                         class="autocomplete__loading"
                         [strokeWidth]="3"
                         [diameter]="30">
            </mat-spinner>
        </mat-option>
        <mat-option *ngFor="let option of (isNotAsynchronous && !isLoading ? (filteredOptions | async) : !isLoading ? options : [])"
                    (onSelectionChange)="onSelectValue($event, option)"
                    [value]="option[sendProperty]">
            {{ option[nameProperty] | translate }}
        </mat-option>
    </mat-autocomplete>
    <mat-icon aria-hidden="true" matSuffix *ngIf="icon">{{ icon }}</mat-icon>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-error *ngIf="formsHelper.isInvalidField(field)">{{ (formsHelper.getErrorMessage(field) | async) }}</mat-error>
</mat-form-field>
