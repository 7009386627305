import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ForceChangePasswordModule } from "../../../modules/force-change-password/force-change-password.module";

@NgModule({
	declarations: [
	],
	imports: [
		CommonModule,
		ForceChangePasswordModule
	]
})
export class MandatoryDialogsModule {
}
