export class CallbackHelpers {
	static keepTrying(callback: CallableFunction, condition: CallableFunction, attempts = 10, timeout = 0.5, onRetry?: CallableFunction) {
		if (attempts <= 0) return;

		if (condition()) callback();
		else setTimeout(
			() => {
				attempts--;
				if (onRetry) onRetry();
				this.keepTrying(callback, condition, attempts, timeout, onRetry);
			}, timeout * 1000);
	}

	static toCallback<T>(value: T | ((...args: any[]) => T)): (...args: any[]) => T{
		type callBack = (...args: any[]) => T;

		if(typeof value !== 'function') return (() => value) as callBack;

		return value as callBack;
	}
}

export const keepTrying = (callback: () => void, condition: () => boolean, configurations: KeepTryingConfig = {}) => {
	const defaultConfig = {
		attempts: 11,
		timeout: 0.5,
		onRetry: () => {}
	};

	const config = {...defaultConfig, ...configurations};

	if (config.attempts <= 0) return;

	if (condition()) callback();
	else setTimeout(
		() => {
			config.attempts--;
			config.onRetry();
			keepTrying(callback, condition, config);

		}, config.timeout * 1000);
};

interface KeepTryingConfig{
	attempts?: number;
	timeout?: number;
	onRetry?: () => void;
}
