import {Generic} from "../models/generic";
import { ObjectModifier } from "./interfaces/object-modifier";
import { ObjectAdapter } from "./interfaces/object-adapter";

export class ObjectHelpers {

    private static readonly numberKeys = [
        "LineOne",
        "LineTwo",
        "LineThree",
        "LineFour",
        "LineFive",
        "LineSix",
    ];

    static getDifferenceBetween(firstObj: Generic, secondObj: Generic) {
        const diff: Generic = {};
        Object.entries(firstObj).map(([key, value]) => {
            const secondValue = secondObj[key];
            if (secondValue === value) return;

            diff[key] = {
                first: value,
                second: secondValue
            };
        });


        if (!Object.entries(diff).length) return;

        return diff;
    }

    static adapt<T>(target: T, adapters: ObjectAdapter<T>[]): Generic {
        return this.map(target, (key, value) => {
            const adapterToUse = adapters.find(adapter => {
                if (!!adapter.condition?.(key, value)) return true;

                if (!Array.isArray(adapter.targetKeys))
                    return key === adapter.targetKeys;

                return adapter.targetKeys.some(targetKey => targetKey === key);
            });

            if (!adapterToUse) return {key, value};

            const source = this.resolveMulti((adapterToUse.sourceKey ?? key).toString(), target);

            if(adapterToUse.shouldRemove && adapterToUse.shouldRemove?.(...[source].flat()))
                return;

            const result = adapterToUse?.adapter?.(...[source].flat()) ?? source;

            return {
                key,
                value: result
            };
        });
    }

    static modifyObject<T>(target: T, modifiers: ObjectModifier<T>[]): Generic {
        const entries = Object.entries(target);

        const newObject: Generic = {};

        modifiers.forEach(modifier => {
            const shouldDelete = !!modifier.delete?.(target);

            if (shouldDelete) return;

            const hasModifier = typeof modifier.modifier === "function";

            const noModifiedProperty = (target as Generic)?.[modifier.key];
            const modifiedProperty =  modifier.modifier?.(target);

            const newObjectKey = modifier.renameTo || modifier.key;

            newObject[newObjectKey] = hasModifier
                ? modifiedProperty
                : noModifiedProperty;
        });

        entries.forEach(([key, value]) => {
            const modifier = modifiers.find(toFind => toFind.key === key);

            if (!modifier) newObject[key] = value;
        });

        return newObject;
    }

    static map<T extends Generic>(
        object: T,
        callback: (key: keyof T, value: T[keyof T]) =>
            { key: string | number | symbol; value: any } | undefined) {
        const newObject: any = {};
        Object.entries(object).map(([key, value]) => {
            const resp = callback(key, value);
            if (!resp) return;
            const {key: k, value: v} = resp;
            newObject[k] = v;
        });

        return newObject;
    }

    static switchKeysAndValues(object: Generic){
        return this.map(object, (key, value) => ({
            key: value,
            value: key
        }));
    }

    static excludeKeys<T extends Generic>(object: T, keys: (keyof T)[]){
        return this.map(object, (key, value) => {
            if(keys.includes(key.toString())) return;

            return {key, value};
        });
    }

    static arrayToObject(array: Generic[]) {
        const asObjects = array.flatMap((element, index) =>
            Object.entries(element).map(([key, value]) => ({
                [`${key}${ObjectHelpers.numberKeys[index]}`]: value
            })));

        return asObjects.reduce((prev, curr) => ({
            ...prev,
            ...curr
        }));
    }

    static objectToArray<T>(object: Generic) {
        return ObjectHelpers.numberKeys.map(suffix => {
            const keys = Object.keys(object).filter(key => key.endsWith(suffix));

            if (!keys.length) return;

            const result = keys.map((key) => ({
                [key.replace(suffix, '')]: object[key]
            })).reduce((prev, curr) => ({...prev, ...curr}), {});

            return result as T;
        }).filter(value => !!value) as T[];
    }

    static convertFields(converter: (value: any) => any, fieldNames: string[], object: Generic) {
        const objectToSend = {...object};

        fieldNames.map((fieldName) => {
            if (object?.[fieldName])
                objectToSend[fieldName] = converter(objectToSend[fieldName]);
        });

        return objectToSend;
    }

    static resolve(path: string | string[], obj: Generic = {}, separator = '.') {
        const properties = Array.isArray(path) ? path : path.split(separator);

        return properties.reduce((prev, curr) => prev && prev[curr], obj);
    }

    static resolveMulti(path: string, obj: Generic = {}, separator = '.') {
        const paths = path.split(',');

        if (paths.length <= 1) return this.resolve(path.trim(), obj, separator);

        return paths.map(pathToResolve =>
            this.resolve(pathToResolve.trim(), obj, separator)
        );
    }
}
