import { Pipe, PipeTransform } from '@angular/core';


import { Menu } from "../../models/menu";
import { Generic } from "../../models/generic";
import { Auxiliary } from "../../helpers/auxiliary";

@Pipe({
    name: 'menuOrder'
})
export class MenuOrderPipe implements PipeTransform {
    constructor(){}

    transform(list: Menu[]): Generic[]{
        const groups: Generic[] = [];

        list.forEach(item => {
            let currentGroup: Generic = {};

            groups.forEach(group => group.name === item.group ? currentGroup = group : null);
                if (Auxiliary.objectHasKeysLength(currentGroup)) currentGroup.list.push(item);
                else groups.push({name: item.group, list: [item]});

        });

        return groups;
    }
}
