import { Injectable } from "@angular/core";
import { ActivatedRoute, QueryParamsHandling, Router } from "@angular/router";

import { map } from "rxjs/operators";

import { routeSelectors } from "../store/selectors";
import { ValidUrlValue } from "../types/valid-url.value";
import { StoreWrapperService } from "../store/store-wrapper.service";
import { UrlRouterParserService } from "./url-router-parser.service";


@Injectable({
	providedIn: "root",
})
export class RouterService {
	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private parser: UrlRouterParserService,
		private store: StoreWrapperService
	) {
	}

	async setQueryParams(params: Record<string, ValidUrlValue>, queryParamsHandling: QueryParamsHandling = "") {
		new Promise<void>((resolve) => {
			setTimeout(() => {
				resolve();
			}, 50);
		});

		await this.router.navigate([], {
			queryParams: this.parser.stringifyParams(params),
			queryParamsHandling,
			relativeTo: this.route,
		});
	}

	async setQueryParamsRemoveNullableFilter(params: Record<string, ValidUrlValue>, ) {
		new Promise<void>((resolve) => {
			setTimeout(() => {
				resolve();
			}, 50);
		});

		await this.router.navigate([], {
			queryParams: this.parser.stringifyParams(params),
			relativeTo: this.route,
		});
	}

	getQueryParams() {
		const allParams = this.store.selectSync(routeSelectors.selectQueryParams) ?? {};

		return this.parser.parseParams(allParams);
	}

	getQueryParams$() {
		return this.route.queryParams.pipe(map(params => this.parser.parseParams(params)))
	}

	single(id: number) {
		const currentUrl = this.store.selectSync(routeSelectors.selectPureUrl);
		this.router?.navigate([currentUrl, id]);
	}
}
