import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-copyright',
    templateUrl: './copyright.component.html',
    styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent{
    @Input() link = 'http://velow.com.br';
    @Input() version: string;
}
