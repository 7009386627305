import { Params, Routes } from "@angular/router";

import { TranslateService } from "@ngx-translate/core";
import { TranslateModuleConfig } from "@ngx-translate/core/public_api";

import { Generic } from "../models/generic";
import { TranslateOption } from "../models/translate-option";
import { Moment } from "./moment";
import { ObjectHelpers } from "./object";

export class Translate {
  static TRANSLATIONS: Generic = {};
  static service: TranslateService;
  static configurations: TranslateModuleConfig = {
    defaultLanguage: 'pt-BR',
    useDefaultLang: true
  };
  static translations: Generic = Translate.TRANSLATIONS[Translate.configurations.defaultLanguage as string];

  static setService(service: TranslateService) {
    Translate.service = service;
    Translate.setTranslations = Translate.translations;
  }

  static setInitialTranslations(translations: Generic) {
    Translate.TRANSLATIONS = translations;
    Translate.translations = Translate.TRANSLATIONS[Translate.configurations.defaultLanguage as string];
  }

  static set setTranslations(translations: Generic) {
    Translate.translations = translations;

    Translate.service.setTranslation(
      Translate.configurations.defaultLanguage as string,
      translations
    );
  }

  static value(path: string = '', options: TranslateOption | null = null, parameters: Params = {}): any {
    options = options ? options : {};

    let value = options.initialValue;
    const service = Translate?.service;
    const keyboardBar = '/';

    if (service) service.get(path, parameters).subscribe(response => value = response);
    else value = ObjectHelpers.resolve(path, Translate.translations) ?? path

    return options.removeSlash && (typeof (value) === 'string' || value instanceof String) && value.startsWith(keyboardBar)
      ?
      value.replace(keyboardBar, '')
      :
      value;
  }

  static translateRoutes(routes: Routes): Routes {
    return routes?.map(route =>
      Translate.deleteUndefinedData({
        ...route,
        redirectTo: Translate.translatePropertyParameter(route.redirectTo, {removeSlash: true}),
        path: Translate.translatePropertyParameter(route.path, {removeSlash: true}),
        // data: {
        //   ...(route?.data || {}),
        //   breadcrumbName: Translate.translatePropertyParameter(route?.data?.breadcrumbName),
        //   metas: Translate.translatePropertyParameter(route?.data?.metas)
        // },
        children: route.children ? Translate.translateRoutes(route.children) : null
      })
    ) as Routes;
  }

  static translatePropertyParameter(property: any, options?: TranslateOption): any {
    return typeof (property) === 'string' ? Translate.value(property, options) : property;
  }

  static deleteUndefinedData(object: Generic = {}): Generic {
    for (const key in object)
      if (object.hasOwnProperty(key)) {
        if (typeof (object[key as keyof typeof object]) === 'undefined')
          delete object[key as keyof typeof object];
        else if (typeof (object[key as keyof typeof object]) === 'object')
          object[key as keyof typeof object] = Translate.deleteUndefinedData(object[key as keyof typeof object]);
      }

    return object;
  }
}

Moment.moment.updateLocale?.((Translate.configurations.defaultLanguage as string).toLowerCase(), null);
