export const CATEGORIES_TRANSLATE = {
    categories: {
        messages: {
            create: "Categoria adicionada com sucesso",
            update: "Categoria atualizada com sucesso",
            delete: "Categoria deletada com sucesso",
            recover: "Categoria recuperada com sucesso"
        },
        list: {
            table: {
                columns: {
                    id: "ID",
                    name: "Nome",
                    complaintType: "Canal de denúncia"
                }
            },
            filters: {
                fields: {
                    id: {
                        label: "ID",
                        placeholder: "Informe o ID",
                        url: "id"
                    },
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome",
                        url: "nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail",
                        url: "email"
                    },
                    complaintType: {
                        label: "Canal de denúncia",
                        placeholder: "Selecione o canal de denúncia",
                        url: "canal-de-denuncia"
                    },
                    active: {
                        label: "Situação",
                        placeholder: "Selecione a situação",
                        url: "situacao"
                    }
                }
            },
            dialog: {
                delete: {
                    title: "Inativar categoria",
                    body: "Tem certeza que deseja inativar a categoria com o ID {{id}}",
                    save: {
                        text: "Sim, inativar"
                    },
                    back: {
                        text: "Voltar"
                    },

                }
            }
        },
        save: {
            form: {
                fields: {
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"
                    },

                    complaintType: {
                        label: "Canal de denúncia",
                        placeholder: "Selecione o canal de denúncia"
                    },

                }
            }
        }
    }
}