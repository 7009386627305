import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { TranslateService } from "@ngx-translate/core";

import { TRANSLATIONS } from "./languages/pt-BR";
import { RoutesGuard } from "./core/guards/routes/routes.guard";
import { Translate } from "./shared/helpers/translate";

Translate.setInitialTranslations(TRANSLATIONS);

const LOGIN_DATA = {
    breadcrumbName: "routes.login.data.breadcrumbName",
    metas: "routes.login.data.metas",
};

const HOME_DATA = {
    breadcrumbName: `routes.home.data.breadcrumbName`,
    metas: `routes.home.data.metas`,
};

const ACCOUNTS_DATA = {
    breadcrumbName: `routes.admin.accounts.data.breadcrumbName`,
    metas: `routes.admin.accounts.data.metas`,
};

const USERS_DATA = {
    breadcrumbName: `routes.account.users.data.breadcrumbName`,
    metas: `routes.account.users.data.metas`,
};

const CATEGORY_DATA = {
    breadcrumbName: "routes.account.categories.data.breadcrumbName",
    metas: "routes.account.categories.data.metas",
};

const COMPLAINT_DATA = {
    breadcrumbName: `routes.complaintsMenu.data.breadcrumbName`,
    metas: `routes.complaintsMenu.data.metas`,
};


const COMMITTEES_DATA = {
    breadcrumbName: "routes.account.committees.data.breadcrumbName",
    metas: "routes.account.committees.data.metas",
};


const WELCOME_DATA = {
    breadcrumbName: `routes.welcome.data.breadcrumbName`,
    metas: `routes.welcome.data.metas`,
};

const NOT_FOUND_DATA = {
    breadcrumbName: "routes.notFound.data.breadcrumbName",
    metas: "routes.notFound.data.metas",
};

const MY_PROFILE_DATA = {
    breadcrumbName: "routes.myProfile.data.breadcrumbName",
    metas: "routes.myProfile.data.metas",
};

const ROUTES: Routes = [
    {
        path: "routes.myProfile.account.path",
        loadChildren: () =>
            import("./modules/my-profile/my-profile.module").then(
                (module) => module.MyProfileModule
            ),
        canActivate: [RoutesGuard],
        data: MY_PROFILE_DATA,
    },

    {
        path: "routes.home.path",
        loadChildren: () =>
            import("./modules/home/home.module").then(
                (module) => module.HomeModule
            ),
        canActivate: [RoutesGuard],
        data: HOME_DATA,
    },

    {
        path: "routes.welcome.path",
        loadChildren: () =>
            import("./modules/welcome/welcome.module").then(
                (module) => module.WelcomeModule
            ),
        canActivate: [RoutesGuard],
        data: WELCOME_DATA,
    },

    {
        path: "routes.admin.accounts.path",
        loadChildren: () =>
            import("./modules/admin/accounts/admin-accounts.module").then(
                (module) => module.AdminAccountsModule
            ),
        canActivate: [RoutesGuard],
        data: ACCOUNTS_DATA,
    },

    {
        path: "routes.admin.login.path",
        loadChildren: () =>
            import("./modules/admin/sign-in/admin-sign-in.module").then(
                (module) => module.AdminSignInModule
            ),
        canActivate: [RoutesGuard],
        data: LOGIN_DATA,
    },

    {
        path: "routes.account.users.path",
        loadChildren: () =>
            import("./modules/users/users.module").then(
                (module) => module.UsersModule
            ),
        canActivate: [RoutesGuard],
        data: USERS_DATA,
    },
    {
        path: "routes.account.categories.path",
        loadChildren: () =>
            import("./modules/categories/categories.module").then(
                (module) => module.CategoriesModule
            ),
        canActivate: [RoutesGuard],
        data: CATEGORY_DATA,
    },

    {
        path: "routes.account.committees.path",
        loadChildren: () =>
            import("./modules/committees/committees.module").then(
                (module) => module.CommitteesModule
            ),
        canActivate: [RoutesGuard],
        data: COMMITTEES_DATA,
    },

    {
        path: "routes.account.complaints.path",
        loadChildren: () =>
            import("./modules/complaints/account/complaints-account.module").then(
                (module) => module.ComplaintsAccountModule
            ),
        canActivate: [RoutesGuard],
        data: COMPLAINT_DATA,
    },

    {
        path: "routes.complaintsMenu.path",
        loadChildren: () =>
            import("./modules/complaints/complaints.module").then(
                (module) => module.ComplaintsModule
            ),
        canActivate: [RoutesGuard],
        data: COMPLAINT_DATA,
    },

    // login
    {
        path: "routes.login.path",
        loadChildren: () =>
            import("./modules/sign-in/sign-in.module").then(
                (module) => module.SignInModule
            ),
        canActivate: [RoutesGuard],
        data: LOGIN_DATA,
    },
    {
        path: "routes.noAccess.path",
        loadChildren: () =>
            import("./modules/no-access/no-access.module").then(
                (module) => module.NoAccessModule
            ),
        canActivate: [RoutesGuard],
        data: {
            breadcrumbName: "routes.noAccess.data.breadcrumbName",
            metas: "routes.noAccess.data.metas",
        },
    },
    {
        path: "routes.notFound.path",
        loadChildren: () =>
            import("./modules/not-found/not-found.module").then(
                (module) => module.NotFoundModule
            ),
        canActivate: [RoutesGuard],
        data: NOT_FOUND_DATA,
    },
    {
        path: "routes.notFound.anything",
        pathMatch: "full",
        loadChildren: () =>
            import("./modules/not-found/not-found.module").then(
                (module) => module.NotFoundModule
            ),
        canActivate: [RoutesGuard],
        data: NOT_FOUND_DATA,
    },
];


@NgModule({
    imports: [
        RouterModule.forRoot(Translate.translateRoutes(ROUTES), {
            relativeLinkResolution: "corrected",
            anchorScrolling: "enabled",
            paramsInheritanceStrategy: "always",
            initialNavigation: 'enabledBlocking'

        }),
    ],
    providers: [],
    exports: [RouterModule],
})
export class AppRoutingModule {
    constructor(private translateService: TranslateService) {
        Translate.setService(this.translateService);
    }
}
