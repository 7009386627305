export const USERS_TRANSLATE = {
    users: {

        messages: {
            create: "Usuário adicionado com sucesso",
            update: "Usuário atualizado com sucesso",
            delete: "Usuário deletado com sucesso",
            recover: "Usuário recuperado com sucesso",
            activeAll: "Permissões ativadas com sucesso",
            inactiveAll: "Permissoes inativadas com sucesso"
        },
        listActions: {
            permissions: {
                text: "Permissões"
            }
        },
        list: {
            dialog: {
                delete: {
                    title: "Inativar usuário",
                    body: "Tem certeza que deseja inativar a conta com o ID {{id}}",
                    save: {
                        text: "Sim, inativar"
                    },
                    back: {
                        text: "Voltar"
                    },

                },
								restore: {
										title: "Restaurar senha",
										body: "Uma nova senha aleatória será gerada e enviada para o e-mail \"{{user.email}}\"",
										actions: {
												primary: "Restaurar",
												secondary: "Voltar"
										}
								},
								resendEmail: {
										title: "Reenviar e-mail de confirmação",
										body: "O e-mail de verificação da conta será enviado novamente para o e-mail: \"{{user.email}}\"",
										actions: {
											primary: "Reenviar",
											secondary: "Voltar"
										}
								}
            },
            table: {
                columns: {
                    id: "ID",
                    name: "Nome",
                    email: "E-mail"
                }
            }
        },
        create: {
            title: "Adicionar usuário"
        },
        update: {
            title: "Editar usuário"
        },
        save: {
            form: {
                fields:{
                    name: {
                        label: "Nome",
                        placeholder: "Informe o nome"
                    },
                    email: {
                        label: "E-mail",
                        placeholder: "Informe o e-mail"
                    },
                    password: {
                        label: "Senha",
                        placeholder: "Informe a senha"
                    },
                    passwordConfirmation: {
                        label: "Confirmar senha",
                        placeholder: "Informe a senha novamente"
                    }
                }
            }
        },
        permissions: {
            title: "Permissões",
            actions: {
                activateAll: {
                    text: "Ativar todas"
                },
                inactivateAll: {
                    text: "Inativar todas"
                },
            }
        }
    }
}
