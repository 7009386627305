<app-form
	[principalTitle]="principalTitle"
	[subTitle]="subtitle"
	(parentOnSubmit)="onSubmit()"
	[form]="form"
	[save]="save"
	[reset]="reset"
>
	<app-form-input
		class="form__field--100 form__field--no-margin-left"
		label="forceChangePassword.fields.currentPassword.label"
		type="password"
		[required]="true"
		[autofocus]="true"
		[maxlength]="fs.maxLength"
		placeholder="forceChangePassword.fields.currentPassword.placeholder"
		[form]="form"
		name="currentPassword">
	</app-form-input>

	<app-form-input
		class="form__field--100 form__field--no-margin-left"
		label="forceChangePassword.fields.password.label"
		type="password"
		[required]="true"
		[maxlength]="fs.maxLength"
		placeholder="forceChangePassword.fields.password.label"
		hint="forceChangePassword.fields.password.hint"
		[form]="form"
		name="password">
	</app-form-input>
	<app-form-input
		class="form__field--100 form__field--no-margin-left"
		label="forceChangePassword.fields.passwordConfirmation.label"
		type="password"
		[required]="true"
		[maxlength]="fs.maxLength"
		placeholder="forceChangePassword.fields.passwordConfirmation.label"
		hint="forceChangePassword.fields.passwordConfirmation.hint"
		[form]="form"
		name="passwordConfirmation">
	</app-form-input>

</app-form>
