import { AbstractControl, FormControl, FormGroup } from "@angular/forms";
import { Params } from "@angular/router";
import { Injectable } from "@angular/core";

import { Observable, of, Subject } from "rxjs";
import { Moment } from "moment";

import { FormValidatorService } from "./form-validator.service";
import { FormErrorFromBackEnd } from "../../../models/form-error-from-back-end";
import { FormMessageType } from "../../../models/form-message-type";
import { FormMessageTypesEnum } from "../../../enums/form-message-types.enum";
import { Auxiliary } from "../../../helpers/auxiliary";
import { Translate } from "../../../helpers/translate";
import { Generic } from "../../../models/generic";
import { DateFilter } from "../../../types/date-filter";

@Injectable({
    providedIn: 'root'
})
export class FormService {
    static maxLength = 250;
    static hourMinuteMask = 'Hh:m0';
    static errorsSubject = new Subject<FormErrorFromBackEnd>();

    static errors: FormMessageType[] = [
        { type: FormMessageTypesEnum.REQUIRED, name: 'required', message: "form.errors.required" },
        { type: FormMessageTypesEnum.EMAIL, name: 'email', message: "form.errors.email" },
        {
            type: FormMessageTypesEnum.MINLENGTH,
            name: 'minlength',
            message: "form.errors.minlength",
            parameters: "`{ \"minlength\": ${field.errors.minlength.requiredLength} }`"
        },
        {
            type: FormMessageTypesEnum.MAXLENGTH,
            name: 'maxlength',
            message: "form.errors.maxlength",
            parameters: "`{ \"maxlength\": ${field.errors.maxlength.requiredLength} }`"
        },
        {
            type: FormMessageTypesEnum.MIN,
            name: 'min',
            message: "form.errors.min",
            parameters: "`{ \"min\": ${field.errors.min.min} }`"
        },
        {
            type: FormMessageTypesEnum.MAX,
            name: 'max',
            message: "form.errors.max",
            parameters: "`{ \"max\": ${field.errors.max.max} }`"
        },
        { type: FormMessageTypesEnum.MAT_DATE_PICKER_PARSE, name: 'matDatepickerParse', message: "form.errors.matDatepickerParse" },
        { type: FormMessageTypesEnum.MAT_START_DATE_INVALID, name: 'matStartDateInvalid', message: "form.errors.matStartDateInvalid" },
        { type: FormMessageTypesEnum.MAT_END_DATE_INVALID, name: 'matEndDateInvalid', message: "form.errors.matEndDateInvalid" },
        { type: FormMessageTypesEnum.MAT_DATE_PICKER_FILTER, name: 'matDatepickerFilter', message: "form.errors.matDatepickerFilter" },
        {
            type: FormMessageTypesEnum.DATE_RANGE_MESSAGE_SEPARATED,
            name: 'dateRangeMessageSeparated',
            message: "form.errors.dateRangeMessageSeparated"
        },
        { type: FormMessageTypesEnum.URL, name: 'url', message: "form.errors.url" },
        { type: FormMessageTypesEnum.CNPJ, name: 'cnpj', message: "form.errors.cnpj" },
        { type: FormMessageTypesEnum.CPF_OR_EMAIL, name: 'cpfOrEmail', message: "form.errors.cpfOrEmail" },
        { type: FormMessageTypesEnum.CEP, name: 'cep', message: "form.errors.cep" },
        { type: FormMessageTypesEnum.PHONE, name: 'phone', message: "form.errors.phone" },
        { type: FormMessageTypesEnum.INVALID_COLOR, name: 'color', message: "form.errors.color" },
        { type: FormMessageTypesEnum.CPF, name: 'cpf', message: "form.errors.cpf" },
        { type: FormMessageTypesEnum.HOUR_MINUTE, name: 'hourMinute', message: "form.errors.hourMinute" },
        { type: FormMessageTypesEnum.CNH, name: 'cnh', message: "form.errors.cnh" },
        { type: FormMessageTypesEnum.LICENSE_PLATE, name: 'licensePlate', message: "form.errors.licensePlate" },
        { type: FormMessageTypesEnum.CUSTOM_ERROR, name: 'customError', message: "" }
    ];

    static maskPhoneWithOptionalChar(fieldName: string, form: FormGroup): string{
        const field = form?.get(fieldName);
        const fieldValue = field?.value;
        const fieldValueLength = fieldValue?.length;
        const defaultValue = '(00) 00009-00000';

        if(!fieldValue) return defaultValue;
        else if(fieldValueLength === 11) return '(00) 00000-0000';
        else if(fieldValueLength <= 10) return '(00) 0000-00000';
        else return '';
    }

    static sendErrorsToForm(errors: FormErrorFromBackEnd): void{
        if(errors && Auxiliary.objectHasKeysLength(errors) && !Auxiliary.isArray(errors) && Auxiliary.isObject(errors))
            FormService.errorsSubject.next(errors);
    }

    static watchErrorsInForm(): Observable<FormErrorFromBackEnd>{
        return FormService.errorsSubject.asObservable();
    }

    static isRequired(required: boolean): boolean {
        return required;
    }

    static isTypeEmail(type: string): boolean{
        return type === 'email';
    }

    static isInvalidField(field: FormControl): boolean{
        return field.invalid;
    }

    static getField(form: FormGroup, name: string): FormControl{
        return form?.get(name) as FormControl;
    }

    static createParametersByForm(form: FormGroup): Params{
        return Auxiliary.onlyValidParameters(form.getRawValue());
    }

    static isDirty(form: FormGroup): boolean{
        return form.dirty;
    }

    static disableSave(form: FormGroup): boolean{
        return form.invalid || form.pristine;
    }

    static clearFields(fields: (AbstractControl|null)[]): void{
        fields.forEach(field => {
            if(field instanceof AbstractControl){
                field.reset();
                field.markAsPristine();
                field.markAsUntouched();
            }
        });
    }

    static getRequired(field: FormControl, required?: boolean): boolean{
        const validator = field.validator && field.validator(field);

        return Auxiliary.isBoolean(required) ?
               required :
               field.validator?.name === "required" || field.errors?.['required'] || validator?.['required'] || false;
    }

    static markAsChanged(field: FormControl): void{
        field.markAsDirty();
        field.markAsTouched();
    }

    static isCustomError(property: string|number): boolean{
        const customErrorObject: FormMessageType =
            FormService.errors.find(error => error.type === FormMessageTypesEnum.CUSTOM_ERROR) as FormMessageType;

        return property === customErrorObject?.name || property === customErrorObject?.type;
    }

    static getErrorMessage(field: FormControl): Observable<string>{
        let errorMessage = '';

        FormService.errors.some((error: FormMessageType) => {
            if(field.hasError(error.name) && !errorMessage){
                if(FormService.isCustomError(error.name)) errorMessage = field.getError(error.name);
                else{
                    Translate.service
                             .get(error.message, error.parameters ? JSON.parse(eval(error.parameters)) : {})
                             .subscribe(message => errorMessage = message);
                }

                return true;
            }

            return false;
        });

        return of(errorMessage);
    }

    static clearForm(form: FormGroup): void{
        form?.reset();
        form?.markAsPristine();
        form?.markAsUntouched();
    }

    static hasNotDefaultFieldChanged(
        parametersObject: Params,
        key: string,
        defaultValues: Generic,
        form: FormGroup
    ): string{
        return parametersObject.hasOwnProperty(key)
               &&
               defaultValues.hasOwnProperty(key)
               &&
               defaultValues[Auxiliary.asKeyof(key, defaultValues)]
               &&
               form.get(key)?.pristine
               ?
               key
               :
               '';
    }

    static dateRangeFilterSeparated(firstFieldName: string, secondFieldName: string, form: FormGroup, isFirst: boolean): DateFilter{
        const startField = form.get(firstFieldName) as FormControl;
        const endField = form.get(secondFieldName) as FormControl;
        // [startField, endField].forEach((field, index) =>
        //     @ts-ignore
        //     eslint-disable-next-line no-underscore-dangle,max-len
        //     field.setValidators([...(field?._rawValidators || []), FormValidatorService.dateRangeErrorSeparated(startField, endField, index === 0)])
        // );

        return (momentDate: Moment) => {
            const date = momentDate?.toDate?.();
            const startVal = startField?.value;
            const endVal = endField?.value;

            if(date && startField && endField){
                if(isFirst && endVal) return date < endVal || date?.toDateString?.() === endVal?.toDateString?.();
                else if(!isFirst && startVal) return date > startVal || date?.toDateString?.() === startVal?.toDateString?.();
                else return true;
            }

            return true;
        };
    }
}

