import { Injectable } from '@angular/core';
import { HttpErrorResponse } from "@angular/common/http";

import { AuthenticationService } from "../authentication/authentication.service";
import { SnackBarService } from "../../shared/components/default/snack-bar/snack-bar.service";
import { Auxiliary } from "../../shared/helpers/auxiliary";
import { Translate } from "../../shared/helpers/translate";
import { FormService } from "../../shared/components/default/form/form.service";
@Injectable({
  providedIn: 'root'
})
export class ErrorsService {
    constructor(
        private authenticationService: AuthenticationService,
        private snackBarService: SnackBarService
    ){}

    setErrorsByResponse(response: HttpErrorResponse): void{
        const responseError = response?.error;
        const errors = responseError?.errors;
        const baseError: string = errors?.base || responseError?.base || responseError?.error?.base;
        const baseErrorInList: string = baseError?.[0];
        const firstErrorsInList: string = errors?.[0];
        const message =
            responseError?.message ||
            (Auxiliary.isString(baseError) && baseError) ||
            (Auxiliary.isString(baseErrorInList) && baseErrorInList) ||
            (Auxiliary.isString(firstErrorsInList) && firstErrorsInList) ||
            (!Auxiliary.isInternalServerError(response) && Auxiliary.isString(responseError) && responseError) ||
            (Auxiliary.isInternalServerError(response) && 'snackBar.messages.internalServerError') ||
            '';

        if(!Auxiliary.isHTML(message)) this.snackBarService.create(message, false);

		const apiError = () => this.snackBarService.create(Translate.value("errors.apiError"), false);

        FormService.sendErrorsToForm(errors);

        if(Auxiliary.isInternalServerError(response)) apiError();
        if(Auxiliary.redirectToLogin(response.status)) this.authenticationService.resetAuthentication();
        // if(Auxiliary.noAccess(response.status)) this.router.navigateByUrl(Translate.value('routes.noAccess.path'));
        // if(this.is405(response.status)) this.router.navigateByUrl(Translate.value('routes.noAccess.path'));
        // if(Auxiliary.is404(response) && !Auxiliary.isBlob(response?.error))

		if(Auxiliary.isApiNotFound(response)){
		this.snackBarService.create(Translate.value('errors.apiIsNotFound'), false);
		this.authenticationService.resetAuthentication();
        }
    }

	//Não consegui carregar algum elemento dessa página. Se isso atrapalhar o seu uso, entre em contato com suporte.


    is405(responseStatus: number){
        return responseStatus === 405;
    }
}
