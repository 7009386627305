import { Component } from '@angular/core';
import { Router } from "@angular/router";

import { Authentication } from "./core/authentication/authentication";
import { SignInService } from "./modules/sign-in/sign-in.service";
import { Translate } from "./shared/helpers/translate";
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent{
    title = 'web';

    authentication = Authentication;

    constructor(
        public signInService: SignInService,
        private router: Router
    ) {


    }


    get showOnlyTemplate(){
        const homeRoute = Translate.value("routes.home.path");
        const welcomeRoute = Translate.value("routes.welcome.path");
        const complaints = Translate.value("routes.complaintsMenu.path");

        const routes = [
            welcomeRoute,
            complaints
        ]

        return !!routes.filter(route => this.router.url.startsWith(route)).length
            || this.signInService.isAtLogin()
            || this.router.url === homeRoute
            || this.router.url === "";
    }
}
