<fieldset class="form">

    <div class="form__head" *ngIf="principalTitle" [ngClass]="{'form__head--dialog': isDialog}">

        <legend class="form__title mat-title" *ngIf="!isDialog; else dialogTitle">{{ principalTitle | translate }}</legend>
        <ng-template #dialogTitle>
            <legend mat-dialog-title class="form__title mat-title" *ngIf="principalTitle">{{ principalTitle | translate }}</legend>
        </ng-template>

        <p class="form__sub-title mat-caption" *ngIf="!isDialog && subTitle" [innerHTML]="subTitle | translate"></p>

    </div>

    <form autocomplete="off" class="form__body" [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!onlyHead">

        <ng-container *ngIf="!isDialog; else dialogContainer">
            <ng-container *ngTemplateOutlet="fields"></ng-container>
        </ng-container>
        <ng-template #dialogContainer>
            <mat-dialog-content #dialogContent class="form__dialog-content" [ngClass]="{'form__dialog-content--has-scroll': hasScroll}">
                <ng-container *ngTemplateOutlet="fields"></ng-container>
            </mat-dialog-content>
        </ng-template>

        <ng-container *ngIf="!isDialog; else dialogActions">
            <ng-container *ngTemplateOutlet="actions"></ng-container>
        </ng-container>
        <ng-template #dialogActions>
            <mat-dialog-actions class="form__dialog-actions">
                <ng-container *ngTemplateOutlet="actions"></ng-container>
            </mat-dialog-actions>
        </ng-template>

    </form>

</fieldset>

<ng-template #fields>
    <div class="form__fields">
        <ng-content></ng-content>
    </div>
</ng-template>

<ng-template #actions>
    <app-form-actions class="form__actions" [secondaryAction]="reset" [primaryAction]="save">
        <ng-content select="[macAction]"></ng-content>
    </app-form-actions>
</ng-template>
