import { createSelector } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';
import { selectApp } from "./app";
import { Translate } from "../../helpers/translate";
import { RegexHelper } from "../../helpers/regex";

const startsWith = (url: string, prefix: string) => {
	const without = url?.replace(/^\//, '');

	return !!without?.startsWith(prefix);
};

export const selectRouter = createSelector(
	selectApp,
	(state) => state.routerState
);

export const selectRoute = createSelector(
	selectApp,
	(state) => state.route
);

export const {
	selectRouteParam,
	selectRouteParams,
	selectQueryParam,
	selectQueryParams,
	selectUrl,
} = fromRouter.getSelectors(selectRouter);


export const selectCurrentUrl = createSelector(
	selectRoute,
	(state) => state.currentUrl
);

export const selectIsAtAdminArea = createSelector(
	selectUrl,
	(url) => startsWith(url, Translate.value("routes.allRoutePrefix.admin"))
)

export const selectIsAtAccountArea = createSelector(
	selectUrl,
	(url) => startsWith(url, Translate.value("routes.allRoutePrefix.account"))
)
export const selectPureUrl = createSelector(
	selectUrl,
	(fullUrl) => RegexHelper.removeAllAfter(fullUrl, "?")
);
